<template>
    <div>
        <b-overlay :show="loading" rounded="sm">
            <div
                v-if="layout != BROADCAST_LAYOUT_RECOMMENDATION"
                class="
                    f
                    d-flex
                    align-items-center
                    justify-content-between
                    flex-nowrap
                    m-0
                    my-2
                    p-2
                    shadow-sm
                    bg-white
                "
                :id="c"
                @click="debHandleBroadcastClick(item)"
            >
                <div
                    class="flex-fill"
                    style="font-size: 115%"
                    v-if="layout == BROADCAST_LAYOUT_LIVE"
                >
                    <div class="d-flex align-items-center">
                        <div>
                            <img
                                :src="
                                    'images/senderlogos/live_ic_tab_' +
                                    item.station_slug +
                                    '.png'
                                "
                                style="max-width: 60px"
                            />
                        </div>
                        <div class="ml-2 flex-fill">
                            <div class="small">
                                <span>
                                    {{ dayjs(item.bc_start).format('HH:mm') }} -
                                    {{ dayjs(item.bc_end).format('HH:mm') }}
                                </span>
                                <span
                                    class="badge badge-primary text-white ml-1"
                                    v-if="item.running"
                                >
                                    <font-awesome-icon
                                        :icon="['fas', 'dot-circle']"
                                    />
                                    LIVE
                                </span>
                            </div>
                            <strong class="d-block text-gray-dark">{{
                                item.bc_title
                            }}</strong>
                            <div v-if="item.running">
                                <div class="progress" style="height: 3px">
                                    <div
                                        class="progress-bar"
                                        :style="{
                                            width: remainingMinutesProgress(
                                                item.bc_start,
                                                item.bc_end
                                            ),
                                        }"
                                    ></div>
                                </div>
                                <small
                                    >läuft
                                    {{ remainingMinutes(item.bc_end) }}</small
                                >
                            </div>
                            <div v-if="!item.running && !item.finished">
                                <small
                                    >beginnt {{ startIn(item.bc_start) }}</small
                                >
                            </div>
                            <div v-if="item.finished">
                                <small>Sendung ist beendet</small>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="flex-fill"
                    style="font-size: 110%"
                    v-if="layout == BROADCAST_LAYOUT_PROGRAM"
                >
                    <div class="small d-flex align-items-center">
                        <span
                            :class="{
                                'text-primary': isPrimeTime,
                                'text-muted': !isPrimeTime,
                            }"
                        >
                            {{ dayjs(item.bc_start).format('HH:mm') }} -
                            {{ dayjs(item.bc_end).format('HH:mm') }}
                        </span>
                        <span class="ml-1 text-muted">
                            <font-awesome-icon
                                :icon="['far', 'bell-slash']"
                                v-if="
                                    item.series !== null &&
                                    !item.series.whitelist.includes(
                                        dayjs(item.bc_start).format('HH:mm')
                                    )
                                "
                            />
                            <font-awesome-icon
                                :icon="['far', 'bell']"
                                class="text-dark"
                                v-if="
                                    item.series !== null &&
                                    item.series.whitelist.includes(
                                        dayjs(item.bc_start).format('HH:mm')
                                    )
                                "
                            />
                        </span>
                        <span
                            class="badge badge-primary text-white ml-2"
                            v-if="item.running"
                        >
                            <font-awesome-icon :icon="['fas', 'dot-circle']" />
                            LIVE
                        </span>
                        <span
                            class="badge badge-primary text-white ml-2"
                            v-if="isPrimeTime"
                        >
                            <font-awesome-icon :icon="['fas', 'medal']" />
                            PRIMETIME
                        </span>
                    </div>
                    <strong class="d-block text-gray-dark">{{
                        item.bc_title
                    }}</strong>
                    <div v-if="item.running">
                        <div class="progress" style="height: 3px">
                            <div
                                class="progress-bar"
                                :style="{
                                    width: remainingMinutesProgress(
                                        item.bc_start,
                                        item.bc_end
                                    ),
                                }"
                            ></div>
                        </div>
                        <small>läuft {{ remainingMinutes(item.bc_end) }}</small>
                    </div>
                </div>

                <div
                    class="d-flex"
                    style="font-size: 115%"
                    v-if="layout == BROADCAST_LAYOUT_SEARCH"
                >
                    <div>
                        <img
                            :src="
                                'images/senderlogos/live_ic_tab_' +
                                item.station_slug +
                                '.png'
                            "
                            :alt="item.station_name"
                            style="max-width: 80px; border: 1px #eee solid"
                        />
                    </div>
                    <div class="ml-2">
                        <small
                            class="text-danger d-block"
                            v-if="dayjs().isBetween(item.bc_start, item.bc_end)"
                        >
                            <font-awesome-icon
                                :icon="['fas', 'dot-circle']"
                                class="mr-1"
                            />Jetzt LIVE
                        </small>
                        <small class="font-weight-bold">
                            {{ dayjs(item.bc_start).format('dd, DD.MM.YYYY') }}
                        </small>
                        <small class="d-block text-gray-dark">
                            {{ item.station_name }}
                            <span class="text-primary mx-1">
                                {{ dayjs(item.bc_start).format('HH:mm') }} -
                                {{ dayjs(item.bc_end).format('HH:mm') }}
                            </span>
                        </small>
                        <strong class="d-block text-gray-dark">
                            {{ item.bc_title }}
                        </strong>
                    </div>
                </div>
                <div
                    class="flex-fill"
                    style="font-size: 115%"
                    v-if="layout == BROADCAST_LAYOUT_RECOMMENDATION_FULL"
                >
                    <div class="d-flex align-items-center">
                        <div class="position-relative">
                            <img
                                class="rounded"
                                :src="item.imdb_image_url"
                                width="100"
                            />
                            <div
                                style="
                                    position: absolute;
                                    bottom: 0;
                                    width: 100%;
                                    text-align: center;
                                "
                            >
                                <img src="../assets/tmdb-2.png" width="90%" />
                            </div>
                        </div>
                        <div class="ml-2 flex-fill">
                            <div
                                class="
                                    small
                                    d-flex
                                    align-items-center
                                    text-gray-dark
                                "
                            >
                                <span>
                                    {{ dayjs(item.bc_start).format('HH:mm') }} -
                                    {{ dayjs(item.bc_end).format('HH:mm') }}
                                </span>
                                <span
                                    class="badge badge-primary text-white ml-1"
                                    v-if="item.running"
                                >
                                    <font-awesome-icon
                                        :icon="['fas', 'dot-circle']"
                                    />
                                    LIVE
                                </span>
                                <span class="mx-1"> |</span
                                ><span> {{ item.station_name }}</span>
                            </div>
                            <strong class="d-block text-gray-dark">{{
                                item.bc_title
                            }}</strong>
                            <div
                                class="small"
                                v-if="item.imdb_rating_count !== null"
                            >
                                <font-awesome-icon
                                    :icon="['fas', 'star']"
                                    v-for="n in Math.ceil(stars)"
                                    :key="'aacc' + n"
                                />
                                <font-awesome-icon
                                    :icon="['far', 'star']"
                                    v-for="n in 5 - Math.ceil(stars)"
                                    :key="'aabb' + n"
                                />

                                <span class="ml-2"
                                    >{{
                                        item.imdb_rating_count
                                    }}
                                    Bewertungen</span
                                >
                            </div>
                            <div
                                class="small text-muted mb-1"
                                v-if="item.imdb_genres"
                            >
                                {{ item.imdb_genres.split(',').join(', ') }}
                            </div>
                            <div v-if="item.running">
                                <div class="progress" style="height: 3px">
                                    <div
                                        class="progress-bar"
                                        :style="{
                                            width: remainingMinutesProgress(
                                                item.bc_start,
                                                item.bc_end
                                            ),
                                        }"
                                    ></div>
                                </div>
                                <small
                                    >läuft
                                    {{ remainingMinutes(item.bc_end) }}</small
                                >
                            </div>
                            <div v-if="!item.running">
                                <small
                                    >beginnt {{ startIn(item.bc_start) }}</small
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="d-flex"
                    style="font-size: 115%"
                    v-if="layout == BROADCAST_LAYOUT_BOOKMARK"
                >
                    <div class="d-flex align-items-center">
                        <div>
                            <img
                                :src="
                                    'images/senderlogos/live_ic_tab_' +
                                    item.station_slug +
                                    '.png'
                                "
                                style="max-width: 60px"
                            />
                        </div>
                        <div class="ml-2 flex-fill">
                            <small class="d-block text-gray-dark">
                                <span>
                                    {{ dayjs(item.bc_start).format('HH:mm') }} -
                                    {{ dayjs(item.bc_end).format('HH:mm') }}
                                </span>
                                <span
                                    class="badge badge-primary text-white ml-1"
                                    v-if="item.running"
                                >
                                    <font-awesome-icon
                                        :icon="['fas', 'dot-circle']"
                                    />
                                    LIVE
                                </span>
                            </small>
                            <strong class="d-block text-gray-dark">{{
                                item.bc_title
                            }}</strong>
                            <div v-if="item.running">
                                <div class="progress" style="height: 3px">
                                    <div
                                        class="progress-bar"
                                        :style="{
                                            width: remainingMinutesProgress(
                                                item.bc_start,
                                                item.bc_end
                                            ),
                                        }"
                                    ></div>
                                </div>
                                <small
                                    >läuft
                                    {{ remainingMinutes(item.bc_end) }}</small
                                >
                            </div>
                            <div v-if="!item.running">
                                <small
                                    >beginnt {{ startIn(item.bc_start) }}</small
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="ml-2 p-1"
                    v-if="
                        item.running ||
                        ((layout == BROADCAST_LAYOUT_BOOKMARK ||
                            layout == BROADCAST_LAYOUT_LIVE) &&
                            startInSeconds(item.bc_start) < 600)
                    "
                >
                    <font-awesome-icon :icon="['fas', 'chevron-right']" />
                </div>
                <div
                    class="ml-2 p-1"
                    v-if="
                        startInSeconds(item.bc_start) > 600 &&
                        !item.finished &&
                        !item.running &&
                        !item.loading &&
                        item.series !== null
                    "
                >
                    <font-awesome-icon
                        :icon="['fas', 'heart']"
                        class="text-primary"
                    />
                </div>
                <div
                    class="ml-2 p-1"
                    v-if="
                        startInSeconds(item.bc_start) > 600 &&
                        !item.finished &&
                        !item.running &&
                        !item.loading &&
                        item.series === null
                    "
                >
                    <font-awesome-icon
                        :icon="['far', 'heart']"
                        class="text-dark"
                    />
                </div>
                <div class="ml-2 p-1" v-if="!item.running && item.loading">
                    <b-spinner
                        small
                        class="text-muted"
                        label="Laden..."
                    ></b-spinner>
                </div>
            </div>
            <div v-else>
                <div class="m-1" :id="c" @click="debHandleBroadcastClick(item)">
                    <div
                        class="mb-1 position-relative"
                        style="
                            border-radius: 1px;
                            width: 150px;
                            height: 220px;
                            background-size: cover;
                        "
                        :style="{
                            'background-image':
                                'url(' + item.imdb_image_url + ')',
                        }"
                    >
                        <div
                            class="w-100 h-100 position-absolute"
                            style="
                                background: #000;
                                opacity: 0.25;
                                z-index: 1000;
                            "
                        ></div>
                        <div
                            class="
                                d-flex
                                position-relative
                                align-items-center
                                justify-content-center
                                h-100
                                w-100
                            "
                            style="z-index: 1001"
                        >
                            <font-awesome-icon
                                v-if="item.running"
                                class="text-white"
                                style="font-size: 35px"
                                :icon="['fas', 'play']"
                            />
                            <span v-else>
                                <font-awesome-icon
                                    v-if="item.series !== null"
                                    class="text-white"
                                    style="font-size: 35px"
                                    :icon="['fas', 'heart']"
                                />
                                <font-awesome-icon
                                    v-else
                                    class="text-white"
                                    style="font-size: 35px"
                                    :icon="['far', 'heart']"
                                />
                            </span>
                        </div>
                    </div>
                    <div v-if="item.running">
                        <div class="progress" style="height: 3px">
                            <div
                                class="progress-bar"
                                :style="{
                                    width: remainingMinutesProgress(
                                        item.bc_start,
                                        item.bc_end
                                    ),
                                }"
                            ></div>
                        </div>
                        <span class="text-muted"
                            >läuft {{ remainingMinutes(item.bc_end) }}</span
                        >
                    </div>
                    <div v-else class="text-muted">
                        <span
                            v-if="
                                dayjs(item.bc_start).isSame(new Date(), 'day')
                            "
                        >
                            Heute um
                            {{ dayjs(item.bc_start).format('HH:mm') }} Uhr</span
                        >
                        <span v-else>
                            {{ dayjs(item.bc_start).format('dd, DD.MM') }},
                            {{ dayjs(item.bc_start).format('HH:mm') }} Uhr</span
                        >
                    </div>
                    <h6 class="my-0 page-title">{{ item.bc_title }}</h6>
                    <span class="small text-muted">{{
                        item.imdb_genres.split(',').join(', ')
                    }}</span>
                </div>
            </div>
        </b-overlay>
        <b-modal
            v-model="broadcast.modal.show"
            scrollable
            hide-footer
            centered
            @hidden="onBroadcastModalHidden()"
        >
            <template #modal-header>
                <div
                    class="
                        w-100
                        d-flex
                        justify-content-between
                        align-items-center
                    "
                >
                    <div
                        class="p-2 h5 mb-0"
                        @click="broadcast.modal.show = false"
                    >
                        <font-awesome-icon :icon="['fas', 'arrow-left']" />
                    </div>
                    <b-dropdown
                        dropleft
                        size="lg"
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                    >
                        <template #button-content>
                            <font-awesome-icon
                                class="text-dark"
                                :icon="['fas', 'ellipsis-v']"
                            />
                        </template>
                        <b-dropdown-item
                            @click="
                                confirm(
                                    'Sendung löschen',
                                    'Möchtest du die Sendung aus deinen Sendungen entfernen?',
                                    popSeries
                                )
                            "
                        >
                            Nicht mehr folgen
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </template>

            <div v-if="!seriesExists">
                Es ist ein Fehler aufgetreten. Bitte versuche es später erneut.
            </div>
            <div v-else>
                <div v-if="!broadcast.modal.loading">
                    <div class="d-flex align-items-center mb-1">
                        <img
                            v-if="imdb !== undefined"
                            :src="selectedBroadcast.imdb_image_url"
                            style="max-width: 85px"
                        />
                        <img
                            v-else
                            :src="
                                'images/senderlogos/live_ic_tab_' +
                                selectedBroadcast.station_slug +
                                '.png'
                            "
                            style="max-width: 75px"
                        />
                        <div class="p-2">
                            <span class="text-muted">{{
                                selectedBroadcast.station_name
                            }}</span>
                            <h5
                                class="font-weight-bold mb-0"
                                v-if="imdb !== undefined"
                            >
                                {{ selectedBroadcast.bc_title }}
                            </h5>
                            <h5 class="font-weight-bold mb-0" v-else>
                                {{ activeSeries.series_name }}
                            </h5>
                            <div
                                v-if="activeSeries.series_rating_count !== null"
                            >
                                <font-awesome-icon
                                    :icon="['fas', 'star']"
                                    v-for="n in Math.ceil(stars)"
                                    :key="'aacc' + n"
                                />
                                <font-awesome-icon
                                    :icon="['far', 'star']"
                                    v-for="n in 5 - Math.ceil(stars)"
                                    :key="'aabb' + n"
                                />

                                <span class="ml-2"
                                    >{{
                                        activeSeries.series_rating_count
                                    }}
                                    Bewertungen</span
                                >
                            </div>
                        </div>
                    </div>
                    <button
                        type="button"
                        class="btn btn-outline-primary btn-block mb-2"
                        v-if="!isUserSeries"
                        @click="pushSeries()"
                    >
                        <font-awesome-icon :icon="['far', 'heart']" />
                        Sendung merken
                    </button>
                    <div v-if="isUserSeries">
                        <div class="text-primary text-center font-weight-bold">
                            <font-awesome-icon :icon="['fas', 'heart']" />
                            Sendung wurde gemerkt
                        </div>
                        <hr />

                        <b-alert
                            :show="hTvSeries === null"
                            dismissible
                            variant="primary"
                            @dismissed="hideHint()"
                        >
                            Für Uhrzeiten mit
                            <font-awesome-icon
                                :icon="['far', 'bell']"
                                class="text-primary"
                            />
                            erhälst du Benachrichtigungen. Für Uhrzeiten mit
                            <font-awesome-icon
                                :icon="['far', 'bell-slash']"
                                class="text-muted"
                            />
                            erhälst du <u>keine</u> Benachrichtigungen.<br />
                            Tippe auf
                            <button
                                class="btn btn-sm btn-outline-dark"
                                style="padding: 0.20rem; 0.625rem"
                            >
                                20:15
                                <font-awesome-icon
                                    :icon="['far', 'bell-slash']"
                                />
                            </button>
                            um die Uhrzeiten anzupassen.
                        </b-alert>

                        <h5 class="page-title mb-0">
                            Ausstrahlungen der nächsten 14 Tage
                        </h5>
                        <div class="mt-2">
                            <div
                                class="d-inline"
                                v-for="(
                                    item, index
                                ) in activeSeries.time_groups"
                                :key="'aaa-' + index"
                            >
                                <button
                                    v-if="
                                        activeUserSeries.whitelist.includes(
                                            item.value
                                        )
                                    "
                                    style="width: 85px"
                                    class="btn btn-sm btn-primary m-1"
                                    @click="popUserSeriesWhitelist(item)"
                                >
                                    {{ item.value }}
                                    <font-awesome-icon
                                        v-if="!item.loading"
                                        :icon="['far', 'bell']"
                                    />
                                    <b-spinner
                                        small
                                        v-else
                                        label="Laden..."
                                    ></b-spinner>
                                </button>
                                <button
                                    v-if="
                                        !activeUserSeries.whitelist.includes(
                                            item.value
                                        )
                                    "
                                    style="width: 85px"
                                    class="btn btn-sm btn-outline-dark m-1"
                                    @click="pushUserSeriesWhitelist(item)"
                                >
                                    {{ item.value }}
                                    <font-awesome-icon
                                        v-if="!item.loading"
                                        :icon="['far', 'bell-slash']"
                                    />
                                    <b-spinner
                                        small
                                        v-else
                                        label="Laden..."
                                    ></b-spinner>
                                </button>
                            </div>
                        </div>

                        <table class="table table-striped table-sm w-100 my-2">
                            <tbody>
                                <tr
                                    v-for="(
                                        item, index
                                    ) in activeSeries.broadcasts"
                                    :key="'zzzk-' + index"
                                >
                                    <td class="text-left" style="width: 85px">
                                        {{
                                            dayjs(item.start).format(
                                                'DD.MM.YYYY'
                                            )
                                        }}
                                    </td>
                                    <!--<td class="text-left" style="width: 50px">-->
                                    <td class="text-left">
                                        {{ dayjs(item.start).format('HH:mm') }}
                                    </td>
                                    <!--
                                    <td class="text-left">
                                        {{ item.title }}
                                    </td>
                                    -->
                                    <td class="text-center" style="width: 30px">
                                        <font-awesome-icon
                                            :icon="['far', 'bell']"
                                            class="text-primary"
                                            v-if="
                                                activeUserSeries.whitelist.includes(
                                                    dayjs(item.start).format(
                                                        'HH:mm'
                                                    )
                                                )
                                            "
                                        />
                                        <font-awesome-icon
                                            :icon="['far', 'bell-slash']"
                                            class="text-dark"
                                            v-if="
                                                !activeUserSeries.whitelist.includes(
                                                    dayjs(item.start).format(
                                                        'HH:mm'
                                                    )
                                                )
                                            "
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div v-else>
                    <b-skeleton width="85%"></b-skeleton>
                    <b-skeleton width="55%"></b-skeleton>
                    <b-skeleton width="70%"></b-skeleton>
                    <b-skeleton width="45%"></b-skeleton>
                </div>
            </div>
        </b-modal>
        <b-modal v-model="google.modal.show" scrollable hide-footer centered>
            <template #modal-header>
                <div
                    class="
                        w-100
                        d-flex
                        justify-content-between
                        align-items-center
                    "
                >
                    <div class="p-2 h5 mb-0" @click="google.modal.show = false">
                        <font-awesome-icon :icon="['fas', 'arrow-left']" />
                    </div>
                </div>
            </template>
            <p>
                Gib jetzt deinen Suchbegriff ein oder wähle aus den Vorschlägen
                anderer Nutzer
            </p>
            <form @submit="onGoogleSearchSubmit">
                <input
                    type="text"
                    v-model="google.searchString"
                    class="form-control"
                    placeholder="Suchbegriff eingeben..."
                />
            </form>
            <div v-if="appVariant !== 'gagatv'">
                <div class="mt-3 mb-1">
                    <strong>Andere Nutzer suchten nach</strong>
                </div>
                <ul
                    class="list-group mb-5"
                    v-if="selectStreamingProvider.modal.startTvResponse.data"
                >
                    <li
                        v-for="(item, index) of randomSearchStrings.slice(0, 4)"
                        :key="index"
                        class="list-group-item"
                        @click="
                            confirmRedirect(() =>
                                addStation(
                                    `https://www.google.de/search?q=${item}`
                                )
                            )
                        "
                    >
                        {{ item }}
                    </li>
                </ul>
            </div>
        </b-modal>
        <b-modal
            v-model="selectStreamingProvider.modal.show"
            scrollable
            hide-footer
            centered
        >
            <template #modal-header>
                <div
                    class="
                        w-100
                        d-flex
                        justify-content-between
                        align-items-center
                    "
                >
                    <div
                        class="p-2 h5 mb-0"
                        @click="selectStreamingProvider.modal.show = false"
                    >
                        <font-awesome-icon :icon="['fas', 'arrow-left']" />
                    </div>
                </div>
            </template>
            <div class="mb-2">
                Wähle deinen Streaminganbieter oder suche selber auf Google
                danach
            </div>
            <div
                class="row align-items-center border-bottom py-2"
                @click="openGoogleModal()"
            >
                <div class="col-3">
                    <img
                        width="75"
                        style="max-width: 100%"
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Google_2015_logo.svg/1280px-Google_2015_logo.svg.png"
                    />
                </div>
                <div class="col-7">
                    <span class="badge badge-success">selber suchen</span><br />
                    Sender selber auf Google suchen
                </div>
                <div class="col-2 text-right">
                    <font-awesome-icon :icon="['fas', 'chevron-right']" />
                </div>
            </div>
            <div
                class="row align-items-center border-bottom py-2"
                v-if="selectStreamingProvider.modal.url.default !== null"
                @click="startStream(selectStreamingProvider.modal.url.default)"
            >
                <div class="col-3">
                    <img
                        width="75"
                        style="max-width: 100%"
                        :src="
                            'images/senderlogos/live_ic_tab_' +
                            item.station_slug +
                            '.png'
                        "
                    />
                </div>

                <div
                    class="col-7"
                    v-if="
                        [
                            'rtl',
                            'rtl-zwei',
                            'vox',
                            'super-rtl',
                            'nitro',
                        ].includes(item.station_slug)
                    "
                >
                    <span class="badge badge-dark">kostenpflichtig</span><br />
                    Kostenpflichtig auf {{ item.station_name }} streamen
                </div>
                <div class="col-7" v-else>
                    <span class="badge badge-warning"
                        >kostenlos mit Anmeldung</span
                    ><br />
                    Kostenlos auf {{ item.station_name }} streamen
                </div>
                <div class="col-2 text-right">
                    <font-awesome-icon :icon="['fas', 'chevron-right']" />
                </div>
            </div>
            <div
                class="row align-items-center border-bottom py-2"
                v-if="selectStreamingProvider.modal.url.joyn !== null"
                @click="startStream(selectStreamingProvider.modal.url.joyn)"
            >
                <div class="col-3">
                    <img
                        width="75"
                        style="max-width: 100%"
                        src="https://upload.wikimedia.org/wikipedia/de/thumb/7/74/Joyn_%28Streaminganbieter%29_logo.svg/800px-Joyn_%28Streaminganbieter%29_logo.svg.png?20191126194036"
                    />
                </div>
                <div class="col-7">
                    <span
                        class="badge badge-warning"
                        v-if="!selectStreamingProvider.modal.url.joynFree"
                        >kostenlos mit Anmeldung</span
                    >
                    <span class="badge badge-success" v-else
                        >kostenlos ohne Anmeldung</span
                    ><br />
                    Kostenlos auf Joyn streamen
                </div>
                <div class="col-2 text-right">
                    <font-awesome-icon :icon="['fas', 'chevron-right']" />
                </div>
            </div>
            <div
                class="row align-items-center border-bottom py-2"
                v-if="selectStreamingProvider.modal.url.zattoo !== null"
                @click="startStream(selectStreamingProvider.modal.url.zattoo)"
            >
                <div class="col-3">
                    <img
                        width="75"
                        style="max-width: 100%"
                        src="https://upload.wikimedia.org/wikipedia/commons/8/83/Zattoo-logo-screen-positive.svg"
                    />
                </div>
                <div
                    class="col-7"
                    v-if="!selectStreamingProvider.modal.url.zattooFree"
                >
                    <span class="badge badge-dark">kostenpflichtig</span><br />
                    Kostenpflichtig auf Zattoo streamen
                </div>
                <div class="col-7" v-else>
                    <span class="badge badge-success"
                        >kostenlos ohne Anmeldung</span
                    ><br />
                    Kostenlos auf Zattoo streamen
                </div>
                <div class="col-2 text-right">
                    <font-awesome-icon :icon="['fas', 'chevron-right']" />
                </div>
            </div>
            <div
                class="row align-items-center border-bottom py-2"
                v-if="selectStreamingProvider.modal.url.waipu !== null"
                @click="startStream(selectStreamingProvider.modal.url.waipu)"
            >
                <div class="col-3">
                    <img
                        width="75"
                        style="max-width: 100%"
                        src="https://upload.wikimedia.org/wikipedia/commons/6/6b/Waipu-tv-logo.png"
                    />
                </div>
                <div class="col-7">
                    <span class="badge badge-dark">kostenpflichtig</span><br />
                    Kostenpflichtig auf waipu.tv streamen
                </div>
                <div class="col-2 text-right">
                    <font-awesome-icon :icon="['fas', 'chevron-right']" />
                </div>
            </div>
        </b-modal>
        <b-modal
            v-model="pro7.modal.show"
            size="md"
            title="Prosieben Gruppe"
            ok-only
            ok-title="weiter"
            centered
            @hidden="pro7ModalHidden()"
        >
            Bei der Prosieben Gruppe musst du dich
            <strong>einmalig anmelden</strong> um LIVE TV sehen zu können.
            <br /><br />
            Nach der Anmeldung kannst du dich mit deinen Zugangsdaten einloggen
            und <strong>bleibst dauerhaft eingeloggt</strong>. <br /><br />
            Deine Anmeldedaten gelten für alle Sender der Prosieben Gruppe:<br /><br />
            <ul class="mb-0">
                <li>ProSieben</li>
                <li>Sat1</li>
                <li>Kabel 1</li>
                <li>Sixx</li>
                <li>Prosieben Maxx</li>
                <li>Kabel 1 Doku</li>
            </ul>
        </b-modal>
        <ad v-model="ad.modal.show" :response="ad.response" />
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Ad from './Ad'
const getCountdown = (start) => {
    const m = Math.ceil(dayjs(start).diff(dayjs(), 'minutes', true))

    const MINUTE = 1
    const HOUR = 60
    const DAY = HOUR * 24

    const days = Math.floor(m / DAY)
    const hDiff = m % DAY
    const hours = Math.floor(hDiff / HOUR)
    const mDiff = hDiff % HOUR
    const minutes = Math.floor(mDiff / MINUTE)

    return [days, hours, minutes]
}
export default {
    name: 'Broadcast',
    components: {
        Ad,
    },
    props: ['item', 'layout', 'c', 'imdb'],
    data: function () {
        return {
            appVariant: process.env.VUE_APP_VARIANT,
            loading: false,
            hTvSeries: null || localStorage.getItem('hTvSeries'),
            dayjs,
            pro7: {
                clicked: true, //localStorage.getItem('hPro7Sat1') || null, hide modal until pro7 streams work again
                modal: {
                    show: false,
                },
                slugs: [
                    'prosieben',
                    'sat-1',
                    'kabel-eins',
                    'prosieben-maxx',
                    'sixx',
                    'kabel-1-doku',
                ],
                response: {},
            },
            ad: {
                modal: {
                    show: false,
                },
                countDown: 3,
                response: {},
            },
            broadcast: {
                modal: {
                    show: false,
                    loading: false,
                    touched: false,
                },
                selected: {},
            },
            google: {
                modal: {
                    show: false,
                    data: {},
                },
            },
            selectStreamingProvider: {
                modal: {
                    show: false,
                    url: {
                        default: null,
                        google: null,
                        joyn: null,
                        waipu: null,
                        zattoo: null,
                    },
                    startTvResponse: {},
                },
            },
            randomSearchStrings: [],
        }
    },
    computed: {
        ...mapGetters('series', {
            userSeries: 'series',
        }),
        ...mapGetters('series', {
            activeSeries: 'first',
        }),
        stars() {
            const x1 = 1
            const y1 = 10
            const x2 = 1
            const y2 = 5
            if (
                Object.keys(this.activeSeries).length &&
                this.activeSeries.series_rating_value
            ) {
                return (
                    ((this.activeSeries.series_rating_value - x1) * (y2 - x2)) /
                        (y1 - x1) +
                    x2
                )
            } else {
                if (this.item.imdb_rating_count) {
                    return (
                        ((this.item.imdb_rating_value - x1) * (y2 - x2)) /
                            (y1 - x1) +
                        x2
                    )
                }
                return null
            }
        },
        selectedBroadcast() {
            return this.broadcast.selected
        },
        activeUserSeries() {
            const f = this.userSeries.find(
                (item) => item.series_id == this.selectedBroadcast.series_id
            )
            if (f !== undefined) {
                return f
            }
            return {}
        },
        isUserSeries() {
            if (Object.keys(this.activeUserSeries).length) {
                return true
            }
            return false
        },
        isPrimeTime() {
            return dayjs(this.item.bc_start)
                .startOf('day')
                .add(20, 'hours')
                .add(15, 'minutes')
                .isSame(this.item.bc_start)
        },
        seriesExists() {
            return this.selectedBroadcast.series_id > 0
        },
    },
    methods: {
        onGoogleSearchSubmit(e) {
            e.preventDefault()
            this.confirmRedirect(() => {
                this.addStation(
                    `https://www.google.de/search?q=${this.google.searchString}`
                )
            })
        },
        openGoogleModal() {
            this.google.searchString = ''
            if (this.selectStreamingProvider.modal.startTvResponse.data) {
                const selectedStation =
                    this.selectStreamingProvider.modal.startTvResponse.data
                        .params
                this.randomSearchStrings = [
                    'ohne anmeldung',
                    'kostenlos ohne anmeldung',
                    'stream',
                    'stream ohne anmeldung',
                    'stream kostenlos ohne anmeldung',
                    'live stream ohne anmeldung',
                    'live stream kostenlos ohne anmeldung legal',
                    'live',
                    'live ohne anmeldung',
                    'live kostenlos ohne anmeldung',
                ].map(
                    (item) => `${selectedStation.label.toLowerCase()} ${item}`
                )

                this.randomSearchStrings.sort(() => Math.random() - 0.5)
                this.google.modal.show = true
            }
        },
        hideHint() {
            this.hTvSeries = 1
            localStorage.setItem('hTvSeries', this.hTvSeries)
        },
        onBroadcastModalHidden() {
            if (this.broadcast.modal.touched) {
                this.$emit('modal-hidden')
            }
        },
        confirmRedirect(okFunc) {
            this.$bvModal
                .msgBoxConfirm(
                    'Sie werden jetzt auf Google weitergeleitet. Auf dort angebotene Inhalte haben wir keinen redaktionellen Einfluss.',
                    {
                        title: 'Sie verlassen unsere App',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'success',
                        okTitle: 'Ja, verstanden',
                        cancelTitle: 'Abbrechen',
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true,
                    }
                )
                .then((value) => {
                    if (value) {
                        okFunc()
                    }
                })
                .catch((err) => {
                    // An error occurred
                })
        },
        confirm(title, description, okFunc) {
            this.$bvModal
                .msgBoxConfirm(description, {
                    title,
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Ja, löschen',
                    cancelTitle: 'Nein, abbrechen',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        okFunc()
                    }
                })
                .catch((err) => {
                    // An error occurred
                })
        },
        syncSeries(items) {
            this.$store.dispatch('series/sync', {
                data: {
                    items,
                },
            })
        },

        async updateSeriesWhitelist(items) {
            return new Promise((resolve, reject) => {
                this.$store
                    .dispatch('series/update', {
                        data: {
                            id: this.activeSeries.series_id,
                            update: {
                                whitelist: JSON.stringify(items),
                            },
                        },
                    })
                    .catch((e) => {
                        this.handleAxiosError(e)
                        reject()
                    })
                    .then(() => {
                        this.nativeBridge('showToast', 'Änderung gespeichert')
                        this.broadcast.modal.touched = true
                        resolve()
                    })
            })
        },
        async pushUserSeriesWhitelist(item) {
            this.hideHint()
            if (!item.loading) {
                let temp = _.cloneDeep(this.activeUserSeries.whitelist)
                temp.push(item.value)
                item.loading = true
                await this.updateSeriesWhitelist(temp)
                item.loading = false
            }
        },
        async popUserSeriesWhitelist(item) {
            this.hideHint()
            if (!item.loading) {
                let temp = _.cloneDeep(this.activeUserSeries.whitelist)
                const idx = temp.indexOf(item.value)
                if (idx > -1) {
                    temp.splice(idx, 1)
                    item.loading = true
                    await this.updateSeriesWhitelist(temp)
                    item.loading = false
                }
            }
        },

        pushSeries() {
            let temp = _.cloneDeep(this.userSeries)
            temp.push({
                series_id: this.selectedBroadcast.series_id,
                bc_id: this.selectedBroadcast.bc_id,
                bc_start: this.selectedBroadcast.bc_start,
            })
            this.syncSeries(temp)
        },
        popSeries() {
            let temp = _.cloneDeep(this.userSeries)
            const idx = temp.findIndex(
                (item2) => item2.series_id == this.selectedBroadcast.series_id
            )
            temp.splice(idx, 1)
            this.syncSeries(temp)
            this.broadcast.modal.show = false
        },
        openBroadcastModal(item) {
            this.broadcast.selected = item
            this.broadcast.modal.show = true
            if (this.selectedBroadcast.series_id > 0) {
                this.broadcast.modal.loading = true
                this.$store
                    .dispatch('series/show', {
                        data: {
                            series_id: this.selectedBroadcast.series_id,
                        },
                    })
                    .catch((e) => {
                        this.handleAxiosError(e)
                    })
                    .then(() => {
                        this.broadcast.modal.loading = false
                    })
            }
        },
        addStation(url) {
            const d = this.selectStreamingProvider.modal.startTvResponse.data
            this.nativeBridge('addStation', {
                ...d.params,
                url,
            })
        },
        handleBroadcastClick(item) {
            if (this.loading) {
                return
            }
            if (
                item.running ||
                (this.layout == this.BROADCAST_LAYOUT_BOOKMARK &&
                    this.startInSeconds(item.bc_start) < 600)
            ) {
                this.loading = true
                const token = this.$store.state.auth.token
                axios
                    .post(
                        '/activity/start-stream',
                        {
                            station_slug: item.station_slug,
                            bc_id: item.bc_id,
                            ad_available:
                                localStorage.getItem('ad_available') == 'true',
                        },
                        {
                            headers: { Authorization: `Bearer ${token}` },
                        }
                    )
                    .then((response) => {
                        if (response.data['fn'] == 'addStation') {
                            let url = response.data.params.url
                            this.selectStreamingProvider.modal.url.google =
                                'https://www.google.de/search?q=' +
                                response.data.params.label +
                                '+stream+ohne+anmeldung'
                            this.selectStreamingProvider.modal.url.default =
                                response.data.params.url
                            this.selectStreamingProvider.modal.url.joyn = null
                            this.selectStreamingProvider.modal.url.zattoo = null
                            this.selectStreamingProvider.modal.url.waipu = null

                            this.selectStreamingProvider.modal.url.joynFree = false
                            this.selectStreamingProvider.modal.url.zattooFree = false
                            switch (response.data.params.slug) {
                                case 'tele-5':
                                    this.selectStreamingProvider.modal.url.zattoo =
                                        'http://embed-zattoo.com/tele-5/'
                                    this.selectStreamingProvider.modal.url.joyn =
                                        'https://www.joyn.de/live-tv/tele-5'

                                    this.selectStreamingProvider.modal.url.joynFree = true
                                    this.selectStreamingProvider.modal.url.zattooFree = true
                                    break
                                case 'rtl':
                                    this.selectStreamingProvider.modal.url.default =
                                        'https://www.tvnow.de/live-tv/rtl'
                                    this.selectStreamingProvider.modal.url.waipu =
                                        'https://www.waipu.tv/sender/rtl/'
                                    this.selectStreamingProvider.modal.url.zattoo =
                                        'https://zattoo.com/de/live/rtl_deutschland'
                                    break
                                case 'rtl-zwei':
                                    this.selectStreamingProvider.modal.url.default =
                                        'https://www.tvnow.de/live-tv/rtl2'
                                    this.selectStreamingProvider.modal.url.waipu =
                                        'https://www.waipu.tv/sender/rtlzwei'
                                    this.selectStreamingProvider.modal.url.zattoo =
                                        'https://zattoo.com/de/live/rtl2_deutschland'
                                    break
                                case 'vox':
                                    this.selectStreamingProvider.modal.url.default =
                                        'https://www.tvnow.de/live-tv/vox'
                                    this.selectStreamingProvider.modal.url.waipu =
                                        'https://www.waipu.tv/sender/vox'
                                    this.selectStreamingProvider.modal.url.zattoo =
                                        'https://zattoo.com/de/live/vox_deutschland'
                                    break
                                case 'nitro':
                                    this.selectStreamingProvider.modal.url.default =
                                        'https://www.tvnow.de/live-tv/nitro'
                                    this.selectStreamingProvider.modal.url.zattoo =
                                        'https://zattoo.com/de/live/rtlnitro_de'
                                    break
                                case 'super-rtl':
                                    this.selectStreamingProvider.modal.url.default =
                                        'https://www.tvnow.de/live-tv/superrtl'
                                    this.selectStreamingProvider.modal.url.zattoo =
                                        'https://zattoo.com/de/live/super_rtl_deutschland'
                                    break
                                case 'sat-1':
                                    this.selectStreamingProvider.modal.url.default =
                                        'https://video.sat1.de/'
                                    this.selectStreamingProvider.modal.url.joyn =
                                        'https://www.joyn.de/mediatheken/sat1'
                                    this.selectStreamingProvider.modal.url.waipu =
                                        'https://www.waipu.tv/sender/sat1/'
                                    this.selectStreamingProvider.modal.url.zattoo =
                                        'https://zattoo.com/de/live/sat1_deutschland'
                                    break
                                case 'kabel-eins':
                                    this.selectStreamingProvider.modal.url.default =
                                        'https://video.kabeleins.de/'
                                    this.selectStreamingProvider.modal.url.joyn =
                                        'https://www.joyn.de/mediatheken/kabel-eins'
                                    this.selectStreamingProvider.modal.url.zattoo =
                                        'https://zattoo.com/de/live/kabel_eins_deutschland'
                                    break
                                case 'prosieben':
                                    this.selectStreamingProvider.modal.url.default =
                                        'https://video.prosieben.de/'
                                    this.selectStreamingProvider.modal.url.joyn =
                                        'https://www.joyn.de/mediatheken/prosieben'
                                    this.selectStreamingProvider.modal.url.waipu =
                                        'https://www.waipu.tv/sender/prosieben/'
                                    this.selectStreamingProvider.modal.url.zattoo =
                                        'https://zattoo.com/de/live/pro7_deutschland'
                                    break
                                case 'prosieben-maxx':
                                    this.selectStreamingProvider.modal.url.default =
                                        'https://video.prosiebenmaxx.de/'
                                    this.selectStreamingProvider.modal.url.joyn =
                                        'https://www.joyn.de/mediatheken/prosieben-maxx'
                                    this.selectStreamingProvider.modal.url.zattoo =
                                        'https://zattoo.com/de/live/pro7maxx'
                                    break
                                case 'comedy-central':
                                    this.selectStreamingProvider.modal.url.default =
                                        'https://www.joyn.de/live-tv/comedy-central'
                                    this.selectStreamingProvider.modal.url.joyn =
                                        'https://www.joyn.de/mediatheken/comedy-central'
                                    this.selectStreamingProvider.modal.url.zattoo =
                                        'https://zattoo.com/de/live/comedycentral_de'
                                    break
                                case 'kabel-1-doku':
                                    this.selectStreamingProvider.modal.url.default =
                                        'https://www.kabeleinsdoku.de/livestream'
                                    this.selectStreamingProvider.modal.url.joyn =
                                        'https://www.joyn.de/mediatheken/kabel-eins-doku'
                                    break
                                case 'n24-doku':
                                    this.selectStreamingProvider.modal.url.default =
                                        'https://www.welt.de/tv-programm-n24-doku/'
                                    break
                                case 'disney-channel':
                                    this.selectStreamingProvider.modal.url.default =
                                        'https://tv.disney.de/'
                                    break
                                case 'dmax':
                                    this.selectStreamingProvider.modal.url.default =
                                        'https://dmax.de/'
                                    this.selectStreamingProvider.modal.url.joyn =
                                        'https://www.joyn.de/mediatheken/dmax'
                                    this.selectStreamingProvider.modal.url.zattoo =
                                        'https://zattoo.com/de/live/dmax'
                                    break
                                case 'eurosport-1':
                                    this.selectStreamingProvider.modal.url.default =
                                        'https://www.eurosport.de/watch/'
                                    this.selectStreamingProvider.modal.url.zattoo =
                                        'https://zattoo.com/de/live/eurosport'
                                    break
                                case 'tlc':
                                    this.selectStreamingProvider.modal.url.default =
                                        'https://tlc.de/'
                                    this.selectStreamingProvider.modal.url.joyn =
                                        'https://www.joyn.de/mediatheken/tlc'
                                    this.selectStreamingProvider.modal.url.zattoo =
                                        'https://zattoo.com/de/live/tlc'
                                    break
                                case 'sat1-gold':
                                    this.selectStreamingProvider.modal.url.default =
                                        'https://www.sat1gold.de/livestream'
                                    this.selectStreamingProvider.modal.url.joyn =
                                        'https://www.joyn.de/mediatheken/sat1-gold'
                                    this.selectStreamingProvider.modal.url.zattoo =
                                        'https://zattoo.com/de/live/sat1gold'
                                    break
                                case 'vox-up':
                                    this.selectStreamingProvider.modal.url.default =
                                        'https://www.tvnow.de/live-tv/voxup'
                                    this.selectStreamingProvider.modal.url.zattoo =
                                        'https://zattoo.com/de/live/vox_up_de'
                                    break
                                case 'rtl-up':
                                    this.selectStreamingProvider.modal.url.default =
                                        'https://www.tvnow.de/live-tv/rtlplus'
                                    this.selectStreamingProvider.modal.url.zattoo =
                                        'https://zattoo.com/de/live/rtl_plus'
                                    break
                                case 'sixx':
                                    this.selectStreamingProvider.modal.url.default =
                                        'https://video.sixx.de/'
                                    this.selectStreamingProvider.modal.url.joyn =
                                        'https://www.joyn.de/mediatheken/sixx'
                                    this.selectStreamingProvider.modal.url.zattoo =
                                        'https://www.waipu.tv/sender/sixx/'
                                    break
                            }
                            if (
                                this.selectStreamingProvider.modal.url.joyn !==
                                    null ||
                                this.selectStreamingProvider.modal.url.waipu !==
                                    null ||
                                this.selectStreamingProvider.modal.url
                                    .zattoo !== null
                            ) {
                                this.selectStreamingProvider.modal.show = true
                                this.selectStreamingProvider.modal.startTvResponse =
                                    response
                                this.loading = false
                                return
                            }
                            this.nb({
                                data: {
                                    fn: 'startTV',
                                    params: {
                                        ...response.data.params,
                                        url,
                                    },
                                },
                            })
                            this.loading = false
                        }
                        if (response.data['params'].show_ad) {
                            localStorage.setItem('last_ad_at', dayjs())
                            localStorage.setItem('ad_loaded', 0)
                            this.ad.response = response
                            this.ad.modal.show = true
                        } else {
                            if (
                                this.pro7.slugs.includes(item.station_slug) &&
                                this.pro7.clicked != 1
                            ) {
                                this.pro7.response = response
                                this.pro7.modal.show = true
                            } else {
                                this.nb(response)
                            }
                        }
                        this.loading = false
                    })
                    .catch((e) => {
                        this.loading = false
                        this.handleAxiosError(e)
                    })
            } else {
                this.openBroadcastModal(item)
            }
        },
        pro7ModalHidden() {
            localStorage.setItem('hPro7Sat1', 1)
            this.pro7.clicked = 1
            this.nb(this.pro7.response)
        },
        startStream(url) {
            this.nativeBridge('startTV', {
                ...this.selectStreamingProvider.modal.startTvResponse.data[
                    'params'
                ],
                url,
                user_agent:
                    'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/108.0.0.0 Safari/537.36',
                gecko: false,
            })
        },
        nb(response) {
            this.nativeBridge(response.data['fn'], response.data['params'])
            this.loading = false
        },
        startInSeconds(start) {
            return dayjs(start).diff(dayjs(), 'seconds')
        },
        startIn(start) {
            let cd = getCountdown(start)
            let t = 'in '

            if (cd[0] > 0) {
                if (cd[0] == 1) {
                    t += '1T'
                } else {
                    t += cd[0] + 'T'
                }
            }

            if (cd[1] > 0) {
                if (cd[0] > 0) {
                    t += ' '
                }
                if (cd[1] == 1) {
                    t += '1Std'
                } else {
                    t += cd[1] + 'Std'
                }
            }
            if (cd[2] > 0) {
                if (cd[0] > 0 || cd[1] > 0) {
                    t += ' '
                }
                if (cd[2] == 1) {
                    t += '1Min'
                } else {
                    t += cd[2] + 'Min'
                }
            }
            return t
        },
        remainingMinutes(end) {
            let cd = getCountdown(end)
            let t = 'noch '
            if (cd[1] > 0) {
                if (cd[1] == 1) {
                    t += '1Std'
                } else {
                    t += cd[1] + 'Std'
                }
            }
            if (cd[2] > 0) {
                if (cd[1] > 0) {
                    t += ', '

                    if (cd[2] == 1) {
                        t += '1Min'
                    } else {
                        t += cd[2] + 'Min'
                    }
                } else {
                    if (cd[2] == 1) {
                        t += '1 Minute'
                    } else {
                        t += cd[2] + ' Minuten'
                    }
                }
            }
            return t
        },
        remainingMinutesProgress(start, end) {
            return (
                100 -
                (dayjs(end).diff(dayjs(), 'minutes') /
                    dayjs(end).diff(dayjs(start), 'minutes')) *
                    100 +
                '%'
            )
        },
    },
    mounted() {
        this.debHandleBroadcastClick = _.debounce(
            this.handleBroadcastClick,
            1000,
            {
                leading: true,
                trailing: false,
            }
        )
    },
}
</script>
