import dayjs from 'dayjs'
import Vue from 'vue'
import moment from 'moment'

const CACHE_LIFETIME = 3600
/*
const test = (data) => {
    let r = data.
};
*/
const state = {
    pStations: [],

    pDay: [],

    pToday: [],
    pNow: [],
    p2015: [],
    p2200: [],
    p2300: [],

    stations: [],
    dates: [],
    search: [],

    created_at: null, // caching
    expired_at: null, // caching
    key: null, // caching
    date: null, // caching

    loading: true,
    c: 0,
}
const getters = {
    dates: (state) => {
        if (state.dates) {
            state.dates.sort()
            return state.dates.map((item) => {
                const djs = dayjs(item)
                return {
                    date: djs,
                    isToday: djs.isSame(new Date(), 'day'),
                }
            })
        } else return []
    },
    pDay: (state, getters, rootState, rootGetters) => {
        const series = rootGetters['series/series']
        const seriesInit = !rootState.series.all.loading
        const userStationsInit = !rootState.user.stations.loading
        if (state.pDay && seriesInit && userStationsInit) {
            const r = state.pDay.map((item) => {
                // every station
                item.sections = item.sections.map((item2) => {
                    // every section
                    const djs = dayjs()
                    item2.data = item2.data
                        .map((item3) => {
                            // every section item
                            item3.running = djs.isBetween(
                                item3.bc_start,
                                item3.bc_end
                            )
                            item3.finished = djs.isAfter(item3.bc_end)
                            item3.series = series.find(
                                (series) => series.series_id == item3.series_id
                            )
                            if (item3.series === undefined) {
                                item3.series = null
                            }
                            item3.c = state.c // hack to update data with specific intervall
                            return item3
                        })
                        .filter((item3) => dayjs().isBefore(item3.bc_end))
                    return item2
                })
                return item
            })
            return _.sortBy(r, [
                function (o) {
                    const idx = this.userStations.findIndex(
                        (item) => item.slug == o.slug
                    )
                    if (idx !== -1) {
                        return idx
                    }
                    return this.stations.length + o.sort
                }.bind({
                    userStations: rootState.user.stations.data,
                    stations: state.stations,
                }),
            ])
        } else return []
    },
    p2015: (state, getters, rootState, rootGetters) => {
        const series = rootGetters['series/series']
        const seriesInit = !rootState.series.all.loading
        if (state.p2015 && seriesInit) {
            const djs = dayjs()
            const r = state.p2015.map((item) => {
                // every section item
                item.running = djs.isBetween(item.bc_start, item.bc_end)
                item.finished = djs.isAfter(item.bc_end)
                item.series = series.find(
                    (series) => series.series_id == item.series_id
                )
                if (item.series === undefined) {
                    item.series = null
                }
                item.c = state.c
                return item
            })
            return _.sortBy(r, [
                function (o) {
                    const idx = this.userStations.findIndex(
                        (item) => item.slug == o.station_slug
                    )
                    if (idx !== -1) {
                        return idx
                    }
                    return this.stations.length + o.sort
                }.bind({
                    userStations: rootState.user.stations.data,
                    stations: state.stations,
                }),
            ])
        } else return []
    },
    p2200: (state, getters, rootState, rootGetters) => {
        const series = rootGetters['series/series']
        const seriesInit = !rootState.series.all.loading
        if (state.p2200 && seriesInit) {
            const djs = dayjs()
            const r = state.p2200.map((item) => {
                // every section item
                item.running = djs.isBetween(item.bc_start, item.bc_end)
                item.finished = djs.isAfter(item.bc_end)
                item.series = series.find(
                    (series) => series.series_id == item.series_id
                )
                if (item.series === undefined) {
                    item.series = null
                }
                item.c = state.c
                return item
            })
            return _.sortBy(r, [
                function (o) {
                    const idx = this.userStations.findIndex(
                        (item) => item.slug == o.station_slug
                    )
                    if (idx !== -1) {
                        return idx
                    }
                    return this.stations.length + o.sort
                }.bind({
                    userStations: rootState.user.stations.data,
                    stations: state.stations,
                }),
            ])
        } else return []
    },
    p2300: (state, getters, rootState, rootGetters) => {
        const series = rootGetters['series/series']
        const seriesInit = !rootState.series.all.loading
        if (state.p2300 && seriesInit) {
            const djs = dayjs()
            const r = state.p2300.map((item) => {
                // every section item
                item.running = djs.isBetween(item.bc_start, item.bc_end)
                item.finished = djs.isAfter(item.bc_end)
                item.series = series.find(
                    (series) => series.series_id == item.series_id
                )
                if (item.series === undefined) {
                    item.series = null
                }
                item.c = state.c
                return item
            })
            return _.sortBy(r, [
                function (o) {
                    const idx = this.userStations.findIndex(
                        (item) => item.slug == o.station_slug
                    )
                    if (idx !== -1) {
                        return idx
                    }
                    return this.stations.length + o.sort
                }.bind({
                    userStations: rootState.user.stations.data,
                    stations: state.stations,
                }),
            ])
        } else return []
    },
    pNow: (state, getters, rootState, rootGetters) => {
        const series = rootGetters['series/series']
        const seriesInit = !rootState.series.all.loading
        if (state.pToday && seriesInit) {
            const r = _.cloneDeep(state.pToday)
                .map((item) => {
                    return item.sections
                        .map((item2) => {
                            // every section item
                            item2 = item2.data.find(
                                (item3) =>
                                    dayjs().isAfter(dayjs(item3.bc_start)) &&
                                    dayjs(dayjs()).isBefore(dayjs(item3.bc_end))
                            )
                            if (item2 !== undefined) {
                                item2.running = true
                                item2.series = series.find(
                                    (series) =>
                                        series.series_id == item2.series_id
                                )
                                if (item2.series === undefined) {
                                    item2.series = null
                                }
                                item2.c = state.c
                            }
                            return item2
                        })
                        .filter((item2) => item2 !== undefined)[0]
                })
                .filter((item) => item !== undefined)

            return _.sortBy(r, [
                function (o) {
                    const idx = this.userStations.findIndex(
                        (item) => item.slug == o.station_slug
                    )
                    if (idx !== -1) {
                        return idx
                    }
                    return this.stations.length + o.sort
                }.bind({
                    userStations: rootState.user.stations.data,
                    stations: state.stations,
                }),
            ])
        } else return []
    },
    pAfter: (state, getters, rootState, rootGetters) => {
        const series = rootGetters['series/series']
        const seriesInit = !rootState.series.all.loading
        if (state.pToday && seriesInit) {
            const r = _.cloneDeep(state.pToday)
                .map((item) => {
                    return item.sections
                        .map((item2) => {
                            // every section item
                            item2 = item2.data.find((item3) =>
                                dayjs().isBefore(dayjs(item3.bc_start))
                            )
                            if (item2 !== undefined) {
                                item2.running = dayjs().isBetween(
                                    item2.bc_start,
                                    item2.bc_end
                                )
                                item2.finished = dayjs().isAfter(item2.bc_end)
                                item2.series = series.find(
                                    (series) =>
                                        series.series_id == item2.series_id
                                )
                                if (item2.series === undefined) {
                                    item2.series = null
                                }
                                item2.c = state.c // hack to update data with specific intervall
                            }
                            return item2
                        })
                        .filter((item2) => item2 !== undefined)[0]
                })
                .filter((item) => item !== undefined)

            return _.sortBy(r, [
                function (o) {
                    const idx = this.userStations.findIndex(
                        (item) => item.slug == o.station_slug
                    )
                    if (idx !== -1) {
                        return idx
                    }
                    return this.stations.length + o.sort
                }.bind({
                    userStations: rootState.user.stations.data,
                    stations: state.stations,
                }),
            ])
        } else return []
    },
    pStations: (state) => {
        if (state.pStations) {
            return state.pStations
        } else return []
    },
    stations: (state) => {
        if (state.stations) {
            return state.stations
        } else return []
    },
    search: (state, getters, rootState, rootGetters) => {
        const series = rootGetters['series/series']
        const seriesInit = !rootState.series.all.loading
        if (state.search && seriesInit) {
            const djs = dayjs()
            return state.search.map((item) => {
                item.running = djs.isBetween(item.bc_start, item.bc_end)
                item.finished = djs.isAfter(item.bc_end)
                item.series = series.find(
                    (series) => series.series_id == item.series_id
                )
                if (item.series === undefined) {
                    item.series = null
                }
                item.c = state.c
                return item
            })
        } else return []
    },
}
const actions = {
    async search(context, payload) {
        return new Promise((resolve, reject) => {
            const token = context.rootState.auth.token
            let query = '?api_token=' + token
            query += '&term=' + payload.data.keyword

            axios
                .get('/search' + query, {
                    cancelToken: payload.options.cancelToken,
                })
                .then((response) => {
                    context.commit('SET_SEARCH_RESULTS', response.data)
                    resolve()
                })
                .catch((e) => {
                    if (axios.isCancel(e)) {
                        resolve()
                    } else {
                        reject(e)
                    }
                })
        })
    },
    async searchWm(context, payload) {
        return new Promise((resolve, reject) => {
            const token = context.rootState.auth.token
            let query = '?api_token=' + token
            query += '&term=' + payload.data.keyword

            axios
                .get('/search-wm' + query, {
                    cancelToken: payload.options.cancelToken,
                })
                .then((response) => {
                    context.commit('SET_SEARCH_RESULTS', response.data)
                    resolve()
                })
                .catch((e) => {
                    if (axios.isCancel(e)) {
                        resolve()
                    } else {
                        reject(e)
                    }
                })
        })
    },
    async fetch(context, payload) {
        const token = context.rootState.auth.token
        let query = '?api_token=' + token
        let today = true
        if (
            payload !== undefined &&
            payload.data !== undefined &&
            payload.data.date
        ) {
            query += '&date=' + payload.data.date
            if (!dayjs(payload.data.date).isSame(new Date(), 'day')) {
                today = false
            }
        }

        return new Promise((resolve, reject) => {
            const key = '/program' + query
            console.log('try to fetch', context.state.key, key)

            if (
                context.state.key == key &&
                dayjs().isBetween(
                    context.state.created_at,
                    context.state.expired_at
                )
            ) {
                // cached
                resolve()
            } else {
                context.state.loading = true
                axios
                    .get(key)
                    .then((response) => {
                        let stations = response.data.stations
                        let program = response.data.program

                        context.dispatch('commitData', {
                            dates: response.data.dates,
                            stations: response.data.stations,
                            program: response.data.program,
                            today,
                        })
                        context.state.loading = false
                        context.state.key = key
                        resolve()
                    })
                    .catch((e) => {
                        context.state.loading = false
                        reject(e)
                    })
            }
        })
    },
    async fetch2(context, payload) {
        const token = context.rootState.auth.token
        return new Promise((resolve, reject) => {
            let m = moment()
            let getParam = ''
            let today = true
            if (payload.data.date) {
                m = moment(payload.data.date)
                getParam += '?date=' + payload.data.date
                if (!dayjs(payload.data.date).isSame(new Date(), 'day')) {
                    today = false
                }
            }

            let d = {
                program: [],
                stations: {},
                dates: {},
            }

            let dates, stations, program
                ;[...Array(14).keys()]
                    .map((i) => {
                        return moment()
                            .startOf('day')
                            .add(i - 1, 'days')
                            .format('YYYY-MM-DD')
                    })
                    .forEach((item) => {
                        const lsProgram = JSON.parse(
                            localStorage.getItem('p-' + item)
                        )
                        if (lsProgram !== null) {
                            d.program.push({
                                date: item,
                                checksum: lsProgram.checksum,
                            })
                            if (moment(item).isSame(m, 'day')) {
                                // TODO: check between 00:00 and 05:00
                                program = lsProgram.data
                            }
                        }
                    })
            const lsDates = JSON.parse(localStorage.getItem('dates'))
            if (lsDates !== null) {
                d.dates.checksum = lsDates.checksum
                dates = lsDates.data
            }

            const lsStations = JSON.parse(localStorage.getItem('stations'))
            if (lsStations !== null) {
                d.stations.checksum = lsStations.checksum
                stations = lsStations.data
            }

            context.dispatch('commitData', { dates, stations, program, today })

            const key = '/sync' + getParam
            if (
                context.key == key &&
                dayjs().isBetween(
                    context.state.created_at,
                    context.state.expired_at
                )
            ) {
                // cached
                resolve()
            } else {
                context.state.loading = true
                axios
                    .post(
                        key,
                        { data: d },
                        {
                            headers: { Authorization: `Bearer ${token}` },
                        }
                    )
                    .then((response) => {
                        if (response.data.dates.updated) {
                            localStorage.setItem(
                                'dates',
                                JSON.stringify({
                                    checksum: response.data.dates.checksum,
                                    data: response.data.dates.data,
                                })
                            )
                            dates = response.data.dates.data
                        }
                        if (response.data.stations.updated) {
                            localStorage.setItem(
                                'stations',
                                JSON.stringify({
                                    checksum: response.data.stations.checksum,
                                    data: response.data.stations.data,
                                })
                            )
                            stations = response.data.stations.data
                        }
                        if (response.data.program.length) {
                            console.log('#UPDATES#')
                            response.data.program.forEach((item, index) => {
                                localStorage.setItem(
                                    'p-' + item.date,
                                    JSON.stringify({
                                        checksum: item.checksum,
                                        data: item.data,
                                    })
                                )
                                if (index == 0) {
                                    // TODO: check between 00:00 and 05:00 // moment(item.date).isSame(moment(), 'day') ||
                                    program = item.data
                                }
                                resolve({ updated: true })
                            })
                        }

                        context.dispatch('commitData', {
                            dates,
                            stations,
                            program,
                            today,
                        })
                        context.state.loading = false
                        resolve()
                    })
                    .catch((e) => {
                        context.state.loading = false
                        reject(e)
                    })
            }
        })
    },
    commitData: ({ state, commit }, payload) => {
        if (payload.dates !== undefined) {
            commit('UPDATE_PROP', {
                prop: 'dates',
                value: payload.dates,
            })
            console.log('+++commiting: dates+++')
        }
        if (payload.stations !== undefined) {
            commit('UPDATE_PROP', {
                prop: 'stations',
                value: payload.stations,
            })
            console.log('+++commiting: stations+++')
        }
        if (payload.program !== undefined) {
            console.log('+++commiting: program+++')
            commit('UPDATE_PROP', {
                prop: 'pDay',
                value: _.cloneDeep(payload.program),
            })
            if (payload.today) {
                commit('UPDATE_PROP', {
                    prop: 'pToday',
                    value: _.cloneDeep(payload.program),
                })
                commit('UPDATE_PROP', {
                    prop: 'p2015',
                    value: _.cloneDeep(payload.program)
                        .map((item) => {
                            if (item.sections[2].data[0] !== undefined) {
                                const lower = dayjs(
                                    item.sections[2].data[0].bc_end
                                )
                                    .startOf('day')
                                    .add(20, 'hours')
                                    .add(14, 'minutes')
                                    .add(59, 'seconds')

                                const upper = dayjs(
                                    item.sections[2].data[0].bc_end
                                )
                                    .startOf('day')
                                    .add(22, 'hours')

                                const f = item.sections[2].data.find(
                                    (item2) =>
                                        dayjs(item2.bc_start).isAfter(lower) &&
                                        dayjs(item2.bc_start).isBefore(upper)
                                )
                                return f
                            }
                            return undefined
                        })
                        .filter((item) => item !== undefined),
                })
                commit('UPDATE_PROP', {
                    prop: 'p2200',
                    value: _.cloneDeep(payload.program)
                        .map((item) => {
                            if (item.sections[2].data[0] !== undefined) {
                                const lower = dayjs(
                                    item.sections[2].data[0].bc_end
                                )
                                    .startOf('day')
                                    .add(21, 'hours')
                                    .add(59, 'minutes')
                                    .add(59, 'seconds')
                                const upper = dayjs(
                                    item.sections[2].data[0].bc_end
                                )
                                    .startOf('day')
                                    .add(23, 'hours')
                                const f = item.sections[2].data.find(
                                    (item2) => {
                                        return (
                                            dayjs(item2.bc_start).isAfter(
                                                lower
                                            ) &&
                                            dayjs(item2.bc_start).isBefore(
                                                upper
                                            )
                                        )
                                    }
                                )

                                return f
                            } else {
                                return undefined
                            }
                        })
                        .filter((item) => item !== undefined),
                })
                commit('UPDATE_PROP', {
                    prop: 'p2300',
                    value: _.cloneDeep(payload.program)
                        .map((item) => {
                            if (item.sections[2].data[0] !== undefined) {
                                const lower = dayjs(
                                    item.sections[2].data[0].bc_end
                                )
                                    .startOf('day')
                                    .add(22, 'hours')
                                    .add(59, 'minutes')
                                    .add(59, 'seconds')
                                const upper = dayjs(
                                    item.sections[2].data[0].bc_end
                                )
                                    .startOf('day')
                                    .add(25, 'hours')
                                const f = item.sections[2].data.find(
                                    (item2) => {
                                        return (
                                            dayjs(item2.bc_start).isAfter(
                                                lower
                                            ) &&
                                            dayjs(item2.bc_start).isBefore(
                                                upper
                                            )
                                        )
                                    }
                                )

                                return f
                            } else {
                                return undefined
                            }
                        })
                        .filter((item) => item !== undefined),
                })
                commit('UPDATE_PROP', {
                    prop: 'pNow',
                    value: _.cloneDeep(payload.program)
                        .map((item) => {
                            return item.sections
                                .map(function (item2) {
                                    return item2.data.find(
                                        (item3) =>
                                            dayjs().isAfter(
                                                dayjs(item3.bc_start)
                                            ) &&
                                            dayjs(dayjs()).isBefore(
                                                dayjs(item3.bc_end)
                                            )
                                    )
                                })
                                .filter((item2) => item2 !== undefined)[0]
                        })
                        .filter((item) => item !== undefined),
                })
            }
            state.created_at = dayjs().toString()
            state.expired_at = dayjs().add(CACHE_LIFETIME, 's').toString()
            state.loading = false
        }
    },
}
const mutations = {
    SET_SEARCH_RESULTS(state, search) {
        Vue.set(state, 'search', search)
    },
    SET_PROGRAM(state, program) {
        Vue.set(state, 'program', program)
        Vue.set(state, 'init', true)
    },
    INCREMENT_COUNTER(state) {
        Vue.set(state, 'c', state.c + 1)
    },
    UPDATE_PROP(state, payload) {
        Vue.set(state, payload.prop, payload.value)
        /*
        if (payload.once === true) {
            if (state[payload.prop].data === undefined) {
                state[payload.prop] = payload.value
            }
        } else {
            state[payload.prop] = payload.value
        }
        */
        //state[payload.prop] = payload.value
        state[payload.prop].loading = false
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
