<template>
    <div>
        <loading v-if="$store.state.program.loading" />
        <div v-else>
            <div class="my-2">
                <button
                    class="btn mr-2"
                    :class="{
                        'btn-primary': selected == 'p2015',
                        'btn-outline-primary': selected != 'p2015',
                    }"
                    @click="selected = 'p2015'"
                    v-if="
                        dayjs().isBefore(
                            dayjs()
                                .startOf('day')
                                .add(22, 'hours')
                        ) &&
                            dayjs().isAfter(
                                dayjs()
                                    .startOf('day')
                                    .add(5, 'hours')
                            )
                    "
                >
                    ab 20:15
                </button>
                <button
                    class="btn mr-2"
                    :class="{
                        'btn-primary': selected == 'p2200',
                        'btn-outline-primary': selected != 'p2200',
                    }"
                    @click="selected = 'p2200'"
                    v-if="
                        dayjs().isBefore(
                            dayjs()
                                .startOf('day')
                                .add(23, 'hours')
                        ) &&
                            dayjs().isAfter(
                                dayjs()
                                    .startOf('day')
                                    .add(5, 'hours')
                            )
                    "
                >
                    ab 22:00
                </button>
                <button
                    class="btn"
                    :class="{
                        'btn-primary': selected == 'p2300',
                        'btn-outline-primary': selected != 'p2300',
                    }"
                    @click="selected = 'p2300'"
                >
                    ab 23:00
                </button>
            </div>

            <b-alert :show="hToday2015 === null &&
                        dayjs().isBefore(
                            dayjs()
                                .startOf('day')
                                .add(20, 'hours')
                                .add(5, 'minutes')
                        )
                " dismissible variant="primary" @dismissed="handleClick()">
                Tippe auf
                <font-awesome-icon :icon="['far', 'heart']" />
                um
                <strong>10 Minuten vor Beginn</strong> der Sendung
                benachrichtigt zu werden.
            </b-alert>

            <div
                v-for="(item, index) in rows"
                :key="index"
                @click="handleClick()"
            >
                <broadcast
                    :layout="BROADCAST_LAYOUT_LIVE"
                    :item="item"
                    :c="item.c"
                />
            </div>
        </div>
    </div>
</template>
<script>
import P2015 from '../../../views/P2015'
export default {
    ...P2015
}
</script>