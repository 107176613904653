var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column",staticStyle:{"height":"100vh","max-height":"100vh"}},[_c('premium',{model:{value:(_vm.modals.premium.show),callback:function ($$v) {_vm.$set(_vm.modals.premium, "show", $$v)},expression:"modals.premium.show"}}),_c('div',{staticClass:"flex-grow-1 flex-fill",staticStyle:{"overflow-y":"auto"}},[(!_vm.historyBack)?_c('div',{staticClass:"w-100 container-fluid"},[_c('div',{staticClass:"row align-items-center py-2 bg-gray text-white"},[_c('div',{staticClass:"col-6 brand d-flex align-items-center"},[_c('span',{staticStyle:{"line-height":"20px"}},[_vm._v("GAGA TV")]),(_vm.subscription && _vm.subscription !== 0)?_c('span',{staticClass:"badge badge-primary ml-2"},[_vm._v("Pro")]):_vm._e()]),_c('div',{staticClass:"col-6 text-right"},[_c('router-link',{ref:"settings",staticClass:"text-white settings",attrs:{"to":{
                            name: 'settings',
                            query: {
                                date: _vm.$route.query.date,
                                history_back: 1,
                                force_update: _vm.$route.query.force_update,
                            },
                        }}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'ellipsis-v']}})],1)],1)])]):_vm._e(),(_vm.historyBack)?_c('div',{staticClass:"w-100 container-fluid",staticStyle:{"position":"fixed","top":"0","left":"0","z-index":"1001"}},[_c('div',{staticClass:"row text-white bg-dark"},[_c('div',{staticClass:"col-12 d-flex"},[_c('div',{staticClass:"px-2 py-4 h5 mb-0",on:{"click":function($event){return _vm.back()}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'arrow-left']}})],1)])])]):_vm._e(),_c('div',{staticClass:"container-fluid",style:([
                _vm.historyBack
                    ? { 'padding-top': '70px' }
                    : { 'padding-top': '0px' } ])},[_c('transition',{attrs:{"name":"fade"}},[_c('router-view')],1)],1)]),(!_vm.historyBack)?_c('div',{staticClass:"w-100 container-fluid"},[_c('div',{staticClass:"row text-white bg-dark"},[_c('div',{staticClass:"col-12 n d-flex flex-nowrap justify-content-around"},[_c('router-link',{ref:"today",staticClass:"d-flex flex-column align-items-center",attrs:{"exact":"","to":{
                        path: 'today',
                        query: {
                            force_update: _vm.$route.query.force_update,
                        },
                    }}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'calendar-check']}}),_c('span',[_vm._v("Heute")])],1),_c('router-link',{ref:"program",staticClass:"d-flex flex-column align-items-center",attrs:{"exact":"","to":{
                        path: 'program',
                        query: {
                            date: _vm.$route.query.date,
                            force_update: _vm.$route.query.force_update,
                        },
                    }}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'bars']}}),_c('span',[_vm._v("Programm")])],1),_c('router-link',{ref:"recommendations",staticClass:"d-flex flex-column align-items-center",attrs:{"exact":"","to":{
                        path: 'recommendations',
                        query: {
                            date: _vm.$route.query.date,
                            force_update: _vm.$route.query.force_update,
                        },
                    }}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'star']}}),_c('span',[_vm._v("Highlights")])],1),_c('router-link',{ref:"tv",staticClass:"d-flex flex-column align-items-center",attrs:{"exact":"","to":{
                        path: 'tv',
                        query: {
                            date: _vm.$route.query.date,
                            force_update: _vm.$route.query.force_update,
                        },
                    }}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'bell']}}),_c('span',[_vm._v("Mein GAGA")])],1)],1)])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }