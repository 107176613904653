<template>
    <div>
        <loading v-if="$store.state.series.recommendations.loading" />
        <div v-else>
            <b-alert
                :show="hTvMovies === null"
                dismissible
                variant="primary"
                @dismissed="hideHint()"
            >
                Hier findest du TOP Filme der nächsten 7 Tage. Tippe auf
                <font-awesome-icon :icon="['far', 'heart']" />
                um
                <strong>10 Minuten vor Beginn</strong> des Films benachrichtigt
                zu werden.
            </b-alert>
            <b-form-select
                v-model="dateQuery"
                :options="dateOptions"
                class="mb-2"
            />
            <div
                v-for="(item, index) in future"
                :key="'f-' + index"
                @click="hideHint()"
            >
                <div
                    v-for="item of joynItems"
                    v-if="index === 0"
                    :key="item.slug"
                    @click="handleJoynItemClick(item)"
                >
                    <div class="b-overlay-wrap position-relative">
                        <div
                            class="
                                f
                                d-flex
                                align-items-center
                                justify-content-between
                                flex-nowrap
                                m-0
                                my-2
                                p-2
                                shadow-sm
                                bg-white
                            "
                        >
                            <div class="flex-fill" style="font-size: 115%">
                                <div class="d-flex align-items-center">
                                    <div class="position-relative">
                                        <img
                                            :src="item.image_url"
                                            width="100"
                                            class="rounded"
                                        />
                                        <div
                                            style="
                                                position: absolute;
                                                bottom: 0px;
                                                width: 100%;
                                                text-align: center;
                                            "
                                        >
                                            <img
                                                src="/img/tmdb-2.048de8df.png"
                                                width="90%"
                                            />
                                        </div>
                                    </div>
                                    <div class="ml-2 flex-fill">
                                        <div
                                            class="
                                                small
                                                d-flex
                                                align-items-center
                                                text-gray-dark
                                            "
                                        >
                                            <span
                                                class="
                                                    badge badge-primary
                                                    text-white
                                                "
                                            >
                                                <font-awesome-icon
                                                    :icon="[
                                                        'fas',
                                                        'dot-circle',
                                                    ]"
                                                />
                                                JETZT
                                            </span>
                                            <span class="ml-1"
                                                >auf
                                                <img
                                                    height="16px"
                                                    src="https://upload.wikimedia.org/wikipedia/de/7/74/Joyn_%28Streaminganbieter%29_logo.svg"
                                            /></span>
                                        </div>
                                        <strong
                                            class="d-block text-gray-dark"
                                            >{{ item.title }}</strong
                                        >
                                        <div class="small">
                                            <font-awesome-icon
                                                :icon="['fas', 'star']"
                                                v-for="n in Math.ceil(
                                                    item.vote_average / 2
                                                )"
                                                :key="'aacc' + n"
                                            />
                                            <font-awesome-icon
                                                :icon="['far', 'star']"
                                                v-for="n in 5 -
                                                Math.ceil(
                                                    item.vote_average / 2
                                                )"
                                                :key="'aaacc' + n"
                                            />
                                            <span class="ml-2"
                                                >{{
                                                    item.vote_count
                                                }}
                                                Stimmen</span
                                            >
                                        </div>
                                        <div class="small text-muted mb-1">
                                            {{ item.genres }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="ml-2 p-1">
                                <font-awesome-icon
                                    :icon="['fas', 'chevron-right']"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <broadcast
                    v-for="(item2, index2) in item.items"
                    :key="'f2' + index2"
                    :layout="BROADCAST_LAYOUT_RECOMMENDATION_FULL"
                    :item="item2"
                    :c="item2.c"
                    imdb
                />
            </div>
            <div v-if="!future.length" class="text-white py-2">
                Leider existieren an diesem Tag keine Empfehlungen für dich.
            </div>
        </div>
    </div>
</template>
<script>
import Recommendations from '../../../views/Recommendations'
export default {
    ...Recommendations,
}
</script>
