<template>
    <div>
        <b-modal
            @ok="ok"
            @hidden="$emit('input', false)"
            title="Werbung entfernen"
            v-model="modal.show"
            no-close-on-backdrop
            header-bg-variant="primary"
            centered
            modal-class="modal-fullscreen"
            body-class=""
            ok-only
            cancel-title="Abbrechen"
        >
            <template #modal-header>
                <div class="text-white text-center rounded w-100 p-1 h5 font-weight-bold">
                    <font-awesome-icon class="display-4 mb-3" :icon="['fas', 'crown']" /> <br /> Pro Version       
                </div>     
            </template>    
            <div style="font-size: 110%">
            <p>
                Jetzt mit einer <strong>einmaligen Zahlung</strong> die Werbung in der App entfernen. 
            </p>
            <ul>
                <li>Werbung dauerhaft entfernen</li>
                <li>Einmalige Zahlung</li>
                <li>Weiterentwicklung unterstützen</li>
                <li>nur <strong>einmalig 1,99€</strong></li>
            </ul>
            </div>
            <template #modal-footer="{ ok, cancel}">
                <button class="btn btn-block btn-primary" @click="ok()">
                    Jetzt Werbung entfernen
                </button>
                <button class="btn btn-block text-dark btn-link" @click="cancel()">
                    Abbrechen
                </button>            
            </template>
        </b-modal>
        <b-modal
            v-model="notifyPremium"
            header-bg-variant="white"
            footer-bg-variant="primary"
            centered
            ok-only
        >
            <template #modal-header>
                <div class="text-white text-center rounded w-100 p-1 h5 font-weight-bold">
                    <font-awesome-icon class="display-3 mb-3 text-primary" :icon="['fas', 'award']" /> <br /> <span class="text-dark">Du hast nun die Pro Version</span>  
                </div>     
            </template>    
            <div style="font-size: 110%" class="text-center my-2">
                   Vielen Dank für deinen Kauf und deine Unterstützung!
            </div>
            <template #modal-footer="{ok}">
                <button class="btn btn-block btn-primary" @click="ok()">
                    OK
                </button>  
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            modal: {
                show: false,
                selected: null
            },         
            products: [
                {
                    name: 'App werbefrei nur für Jonas', 
                    description: 'Verzichte in Zukunft auf Werbung von unserer App',
                    price: '3.99', 
                    lifetime: true, 
                }
            ]
        }
    },
    watch: {
       	value: function(n) {  
            this.modal.show = n
        },
    },
    computed: {
        notifyPremium: {
            get: function () {
                return this.$store.state.user.notify_premium
            },
            set: function() {}
        },    
    },
    methods: {
        ok() {
            this.nativeBridge(
                'openPurchase',
                'adfree_ot_nc_3.99'
            )
            localStorage.setItem('banner-payment-hide', true)   
        },     
    },
    created() {
        this.modal.show = this.value
        this.nativeBridge(
            'getProductInfo',
            'adfree_ot_nc_3.99'
        )
    }
}
</script>
