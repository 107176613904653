var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-alert',{attrs:{"show":true,"dismissible":"","variant":"primary"},on:{"dismissed":function($event){return _vm.hideHint()}}},[_vm._v(" 💡 Tipp: Unsere Redaktion aktualisiert jeden Tag die Liste der Filme. Es lohnt sich also jeden Tag hier reinzuschauen. ")]),_c('div',{staticClass:"py-3 mb-1 mt-n2",staticStyle:{"overflow-x":"auto"}},[_c('div',{staticClass:"d-flex"},_vm._l((_vm.categories),function(category){return _c('div',{key:category.slug,staticClass:"\n                    border\n                    bg-white\n                    pointer\n                    px-3\n                    py-2\n                    mr-1\n                    font-weight-bold\n                    rounded\n                ",class:{
                    'border-dark': category.slug === _vm.selectedCategorySlug,
                },staticStyle:{"white-space":"nowrap"},on:{"click":function($event){return _vm.selectCategory(category.slug)}}},[_vm._v(" "+_vm._s(category.label)+" ")])}),0)]),(_vm.loading)?_c('loading'):_c('div',[(_vm.items.length === 0)?_c('div',[_vm._v(" Unsere Redaktion sucht für dich gerade die besten Filme. Schau doch einfach später noch einmal vorbei. ")]):_c('div',{staticClass:"row row-smaller"},_vm._l((_vm.items),function(item,index){return _c('div',{key:item.slug,staticClass:"col-6 positiion-relative mb-3"},[_c('div',{staticClass:"w-100 h-100 bg-white rounded",on:{"click":function($event){return _vm.handleJoynItemClick(item.slug)}}},[_c('image-item',{staticClass:"w-100 mb-2",attrs:{"index":index,"source":item.image_url}}),_c('div',{staticClass:"p-1"},[_c('div',{staticClass:"small d-flex align-items-center"},[_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._l((Math.ceil(
                                        item.vote_average / 2
                                    )),function(n){return _c('font-awesome-icon',{key:'aacc' + n,attrs:{"icon":['fas', 'star']}})}),_vm._l((5 -
                                    Math.ceil(item.vote_average / 2)),function(n){return _c('font-awesome-icon',{key:'aaacc' + n,attrs:{"icon":['far', 'star']}})})],2),_c('span',{staticClass:"ml-1 text-muted",staticStyle:{"overflow":"hidden","white-space":"nowrap"}},[_vm._v(_vm._s(item.vote_count)+" Stimmen")])]),_c('strong',[_vm._v(_vm._s(item.title.length > 40 ? item.title.substring(0, 40) + '...' : item.title))])])],1)])}),0)]),_c('b-modal',{attrs:{"no-close-on-backdrop":"","header-bg-variant":"dark","centered":"","modal-class":"modal-fullscreen","body-class":"","ok-only":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('div',{staticClass:"\n                    text-white text-center\n                    rounded\n                    w-100\n                    p-1\n                    h5\n                    mb-0\n                    font-weight-bold\n                "},[_vm._v(" 💡 Filmtipp des Tages ")])]},proxy:true},{key:"modal-footer",fn:function(){return [_c('button',{staticClass:"btn btn-block btn-success",on:{"click":function($event){return _vm.startMovie()}}},[_vm._v(" ▶ Kostenlos starten ")]),_c('button',{staticClass:"btn btn-block text-dark btn-link",on:{"click":function($event){return _vm.hideModal()}}},[_vm._v(" andere Filme anzeigen ")])]},proxy:true}]),model:{value:(_vm.modal.show),callback:function ($$v) {_vm.$set(_vm.modal, "show", $$v)},expression:"modal.show"}},[(Object.keys(_vm.modal.movie).length)?_c('div',{on:{"click":_vm.startMovie}},[_c('image-item',{staticClass:"w-100",attrs:{"index":0,"source":_vm.modal.movie.image_url}}),_c('div',{staticClass:"mt-1"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticStyle:{"white-space":"nowrap"}},[_vm._l((Math.ceil(
                                _vm.modal.movie.vote_average / 2
                            )),function(n){return _c('font-awesome-icon',{key:'aacc' + n,attrs:{"icon":['fas', 'star']}})}),_vm._l((5 -
                            Math.ceil(_vm.modal.movie.vote_average / 2)),function(n){return _c('font-awesome-icon',{key:'aaacc' + n,attrs:{"icon":['far', 'star']}})})],2),_c('span',{staticClass:"ml-2 text-muted",staticStyle:{"overflow":"hidden","white-space":"nowrap"}},[_vm._v(_vm._s(_vm.modal.movie.vote_count)+" Bewertungen")])]),_c('div',_vm._l((_vm.modal.movie.genres.split(', ')),function(item){return _c('span',{staticClass:"badge badge-secondary mr-1"},[_vm._v(_vm._s(item))])}),0),_c('strong',{staticStyle:{"color":"#000"}},[_vm._v(_vm._s(_vm.modal.movie.title))]),_c('p',{staticClass:"text-muted small mb-0"},[_vm._v(" "+_vm._s(_vm.modal.movie.description)+" ")])])],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }