<template>
    <div class="d-flex flex-column" style="height: 100vh; max-height: 100vh">
        <premium v-model="modals.premium.show" />
        <div class="flex-grow-1 flex-fill" style="overflow-y: auto">
            <div class="w-100 container-fluid" v-if="!historyBack">
                <div class="row align-items-center py-2 bg-gray text-white">
                    <div class="col-6 brand d-flex align-items-center">
                        <span style="line-height: 20px">GAGA TV</span>
                        <span
                            class="badge badge-primary ml-2"
                            v-if="subscription && subscription !== 0"
                            >Pro</span
                        >
                    </div>
                    <div class="col-6 text-right">
                        <!--<span class="badge badge-primary p-1 mr-4" v-if="!subscription && subscription !== 0" @click="openPremiumModal()"><font-awesome-icon :icon="['fas', 'crown']" /> Pro holen</span>-->
                        <router-link
                            class="text-white settings"
                            ref="settings"
                            :to="{
                                name: 'settings',
                                query: {
                                    date: $route.query.date,
                                    history_back: 1,
                                    force_update: $route.query.force_update,
                                },
                            }"
                        >
                            <font-awesome-icon :icon="['fas', 'ellipsis-v']" />
                        </router-link>
                    </div>
                </div>
            </div>

            <div
                class="w-100 container-fluid"
                style="position: fixed; top: 0; left: 0; z-index: 1001"
                v-if="historyBack"
            >
                <div class="row text-white bg-dark">
                    <div class="col-12 d-flex">
                        <div class="px-2 py-4 h5 mb-0" @click="back()">
                            <font-awesome-icon :icon="['fas', 'arrow-left']" />
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="container-fluid"
                :style="[
                    historyBack
                        ? { 'padding-top': '70px' }
                        : { 'padding-top': '0px' },
                ]"
            >
                <!--<div id="log" class="border border-dark p-2"></div>-->
                <transition name="fade">
                    <router-view></router-view>
                </transition>
            </div>
        </div>
        <div class="w-100 container-fluid" v-if="!historyBack">
            <div class="row text-white bg-dark">
                <div class="col-12 n d-flex flex-nowrap justify-content-around">
                    <router-link
                        class="d-flex flex-column align-items-center"
                        ref="today"
                        exact
                        :to="{
                            path: 'today',
                            query: {
                                force_update: $route.query.force_update,
                            },
                        }"
                    >
                        <font-awesome-icon :icon="['fas', 'calendar-check']" />
                        <span>Heute</span>
                    </router-link>
                    <router-link
                        class="d-flex flex-column align-items-center"
                        ref="program"
                        exact
                        :to="{
                            path: 'program',
                            query: {
                                date: $route.query.date,
                                force_update: $route.query.force_update,
                            },
                        }"
                    >
                        <font-awesome-icon :icon="['fas', 'bars']" />
                        <span>Programm</span>
                    </router-link>
                    <router-link
                        class="d-flex flex-column align-items-center"
                        ref="recommendations"
                        exact
                        :to="{
                            path: 'recommendations',
                            query: {
                                date: $route.query.date,
                                force_update: $route.query.force_update,
                            },
                        }"
                    >
                        <font-awesome-icon :icon="['fas', 'star']" />
                        <span>Highlights</span>
                    </router-link>
                    <router-link
                        class="d-flex flex-column align-items-center"
                        ref="tv"
                        exact
                        :to="{
                            path: 'tv',
                            query: {
                                date: $route.query.date,
                                force_update: $route.query.force_update,
                            },
                        }"
                    >
                        <font-awesome-icon :icon="['fas', 'bell']" />
                        <span>Mein GAGA</span>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss">
@import './scss/app.scss';
</style>
<script>
import { EventBus } from '@/event-bus'
import Premium from '../../components/Premium'
export default {
    components: {
        Premium,
    },
    data: function () {
        return {
            interval: null,
            updateIntervalMs: 10000,
            historyBack: false,
            recentModalIds: [],
            prevRouteName: null,
            modals: {
                premium: {
                    show: false,
                },
            },
        }
    },
    computed: {
        subscription() {
            return this.$store.state.user.subscription
        },
    },
    watch: {
        $route(to, from) {
            if (
                this.$refs[from.name] !== undefined &&
                this.$refs[from.name].$el !== undefined &&
                this.$refs[from.name].$el !== null
            )
                this.$refs[from.name].$el.classList.remove('router-link-active')

            if (
                to.name == 'settings' ||
                to.name == 'live' ||
                to.name == 'p2015' ||
                to.name == 'p2200' ||
                to.name == 'bookmarks' ||
                to.name == 'search'
            ) {
                this.historyBack = true
            } else {
                this.historyBack = false
            }
            if (from.name != to.name) {
                this.prevRouteName = from.name
            }
        },
    },
    methods: {
        onBackPressed() {
            if (this.recentModalIds.length) {
                this.$bvModal.hide(
                    this.recentModalIds[this.recentModalIds.length - 1]
                )
            }
        },
        onProductInfo(e) {
            //alert(JSON.stringify(e.detail))
        },
        openPremiumModal() {
            EventBus.$emit('onPremiumRequested')
        },
        fetchSettings() {
            this.$store.dispatch('user/fetchSettings').catch((e) => {
                this.handleAxiosError(e)
            })
        },
        back() {
            if (this.prevRouteName !== null) {
                this.$router.push({ path: '/' + this.prevRouteName })
            } else {
                this.$router.push({ path: '/today' })
            }
        },
        u() {
            this.$store.commit('program/INCREMENT_COUNTER')
            this.$store.commit('series/INCREMENT_COUNTER')
        },
    },
    beforeMount() {
        if (
            this.$router.currentRoute.query.api_token &&
            this.$router.currentRoute.query.api_token.length == 32
        ) {
            localStorage.setItem(
                'api_token',
                this.$router.currentRoute.query.api_token
            )
            this.$store.state.auth.token =
                this.$router.currentRoute.query.api_token
        } else if (localStorage.getItem('api_token') !== null) {
            this.$store.state.auth.token = localStorage.getItem('api_token')
        } else {
            alert(
                'Es ist ein Fehler aufgetreten. Bitte versuche die App erneut zu starten.'
            )
        }

        if (
            this.$router.currentRoute.query.v &&
            this.$router.currentRoute.query.v.length > 0
        ) {
            localStorage.setItem(
                'app_version',
                this.$router.currentRoute.query.v
            )
        }
    },
    mounted() {
        if (document.getElementById('loading') !== undefined) {
            document.getElementById('loading').remove()
        }

        /* ads */
        this.nativeBridge('loadAd')
        setTimeout(() => {
            localStorage.setItem('ad_loaded', 1)
        }, 4000)
        this.interval = setInterval(() => {
            this.u()
            let lastAdAt = localStorage.getItem('last_ad_at')
            let adLoaded = !!parseInt(localStorage.getItem('ad_loaded'))
            if (
                dayjs(lastAdAt).isBefore(dayjs().subtract('10', 'minute')) &&
                !adLoaded
            ) {
                this.nativeBridge('loadAd')
                setTimeout(() => {
                    localStorage.setItem('ad_loaded', 1)
                }, 4000)
            }
        }, this.updateIntervalMs)

        if (
            this.$refs[this.$router.currentRoute.name] !== undefined &&
            this.$refs[this.$router.currentRoute.name].$el !== undefined
        ) {
            this.$refs[this.$router.currentRoute.name].$el.classList.add(
                'router-link-active'
            )
        }

        window.addEventListener('onBackPressed', this.onBackPressed)
        window.addEventListener('onProductInfo', this.onProductInfo)

        this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
            this.$router.push({ query: { modal: 1 } })
            this.recentModalIds.push(modalId)
        })
        this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
            this.recentModalIds.splice(-1, 1)
            if (this.recentModalIds.length == 0) {
                let query = Object.assign({}, this.$route.query)
                delete query.modal
                this.$router.replace({ query })
            }
        })

        EventBus.$on('onPremiumRequested', () => {
            this.modals.premium.show = true
        })

        this.fetchSettings()

        // cleanup
        ;[...Array(14).keys()]
            .map((i) => {
                return dayjs()
                    .startOf('day')
                    .add((i + 1) * -1, 'days')
                    .format('YYYY-MM-DD')
            })
            .forEach((item) => {
                const key = 'p-' + item
                console.log('trying to remove: ' + key)
                localStorage.removeItem(key)
            })

        let that = this
        document.addEventListener('keyup', function (evt) {
            if (evt.keyCode === 27) {
                that.onBackPressed()
            }
        })
    },
    beforeDestroy() {
        clearInterval(this.interval)
    },
}
</script>
