<template>
    <div class="text-white">
        <payment v-model="modals.payment.show" />
        <div v-if="!subscription">
            <div
                class="d-flex align-items-center justify-content-between py-1"
                @click="openPremiumModal()"
            >
                <div>
                    <h5 class="font-weight-bold mb-0">
                        <font-awesome-icon
                            :icon="['fas', 'crown']"
                            class="mr-1"
                        />
                        Pro Version
                    </h5>
                    <small class="text-muted">Werbung entfernen</small>
                </div>
                <font-awesome-icon :icon="['fas', 'angle-right']" />
            </div>
            <hr class="my-4" />
        </div>
        <div class="d-flex align-items-center justify-content-between mt-4">
            <div @click="wifi_only = !wifi_only">
                <h5 class="font-weight-bold mb-0">Nur über WLAN streamen</h5>
                <small class="text-muted"
                    >Damit nicht dein mobiles Datenvolumen verbraucht
                    wird</small
                >
            </div>
            <div class="pl-4">
                <b-form-checkbox
                    v-model="wifi_only"
                    switch
                    size="lg"
                ></b-form-checkbox>
            </div>
        </div>
        <hr class="my-4" />
        <div
            class="d-flex align-items-center justify-content-between py-1"
            @click="modals.report.show = true"
        >
            <h5 class="font-weight-bold mb-0">Problem melden</h5>
            <font-awesome-icon :icon="['fas', 'angle-right']" />
        </div>
        <hr class="my-4" />
        <div
            class="d-flex align-items-center justify-content-between py-1"
            @click="modals.suggestion.show = true"
        >
            <h5 class="font-weight-bold mb-0">Sender vorschlagen</h5>
            <font-awesome-icon :icon="['fas', 'angle-right']" />
        </div>
        <hr class="my-4" />
        <div
            class="d-flex align-items-center justify-content-between py-1"
            @click="modals.imprint.show = true"
        >
            <h5 class="font-weight-bold mb-0">Impressum</h5>
            <font-awesome-icon :icon="['fas', 'angle-right']" />
        </div>
        <hr class="my-4" />
        <div
            class="d-flex align-items-center justify-content-between py-1"
            @click="modals.privacy.show = true"
        >
            <h5 class="font-weight-bold mb-0">Datenschutz</h5>
            <font-awesome-icon :icon="['fas', 'angle-right']" />
        </div>
        <hr class="my-4" />
        <div
            class="d-flex align-items-center justify-content-between py-1"
            @click="modals.copyright.show = true"
        >
            <h5 class="font-weight-bold mb-0">Copyright Hinweise</h5>
            <font-awesome-icon :icon="['fas', 'angle-right']" />
        </div>
        <hr class="my-4" />
        <b-modal
            v-model="modals.report.show"
            centered
            title="Ein Problem melden"
            cancel-title="Abbrechen"
            ok-title="Problem melden"
            @shown="focusInput"
            @ok="onSubmitReport"
        >
            <form>
                <div class="alert alert-success" v-if="modals.report.success">
                    <strong>Vielen Dank für dein Feedback!</strong><br />Wir
                    kümmern uns schnellstmöglich um das Problem. Falls du eine
                    E-Mail angegeben hast melden wir uns bei dir!
                </div>
                <div class="alert alert-danger" v-if="modals.report.error">
                    <strong>Hoppla!</strong><br />Beim Absenden des Formulars
                    ist ein Fehler aufgetreten. Bitte probiere es in Kürze
                    erneut.
                </div>
                <div class="form-group">
                    <label for="email" class="mb-0 font-weight-bold"
                        >E-Mail
                        <small
                            >(nicht nötig, aber nur so können wir dir
                            antworten)</small
                        ></label
                    >
                    <input
                        type="email"
                        class="form-control"
                        id="email"
                        name="email"
                        v-model="$v.modals.report.form.email.$model"
                        placeholder="Eingeben..."
                        :class="{ 'is-invalid': !validateState('email') }"
                    />
                </div>
                <div class="form-group">
                    <label for="message" class="mb-0 font-weight-bold"
                        >Nachricht <small>(nicht nötig)</small></label
                    >
                    <textarea
                        class="form-control"
                        name="message"
                        ref="focusThis"
                        id="message"
                        placeholder="Eingeben..."
                        rows="4"
                        v-model="modals.report.form.message"
                    ></textarea>
                </div>
            </form>
        </b-modal>

        <b-modal
            v-model="modals.suggestion.show"
            centered
            title="Verbesserungen vorschlagen"
            cancel-title="Abbrechen"
            ok-title="Vorschlag absenden"
            @shown="focusInput2"
            @ok="onSubmitSuggestion"
        >
            <form>
                <div
                    class="alert alert-success"
                    v-if="modals.suggestion.success"
                >
                    <strong>Vielen Dank für dein Feedback!</strong><br />Wir
                    kümmern uns schnellstmöglich um das Problem. Falls du eine
                    E-Mail angegeben hast melden wir uns bei dir!
                </div>
                <div class="alert alert-danger" v-if="modals.suggestion.error">
                    <strong>Hoppla!</strong><br />Beim Absenden des Formulars
                    ist ein Fehler aufgetreten. Bitte probiere es in Kürze
                    erneut.
                </div>
                <div class="form-group">
                    <label for="email" class="mb-0 font-weight-bold"
                        >E-Mail
                        <small
                            >(nicht nötig, aber nur so können wir dir
                            antworten)</small
                        ></label
                    >
                    <input
                        type="email"
                        class="form-control"
                        id="email"
                        name="email"
                        v-model="$v.modals.suggestion.form.email.$model"
                        placeholder="Eingeben..."
                        :class="{ 'is-invalid': !validateState2('email') }"
                    />
                </div>
                <div class="form-group">
                    <label for="message" class="mb-0 font-weight-bold"
                        >Nachricht <small>(nicht nötig)</small></label
                    >
                    <textarea
                        class="form-control"
                        name="message"
                        ref="focusThis2"
                        id="message"
                        placeholder="Eingeben..."
                        rows="4"
                        v-model="modals.suggestion.form.message"
                    ></textarea>
                </div>
            </form>
        </b-modal>
        <b-modal
            v-model="modals.about.show"
            centered
            title="Über diese App"
            hide-footer
        >
            <strong>Name</strong><br />
            Deutsches Fernsehen - LIVE TV kostenlos gucken<br /><br />
            <strong>Email</strong><br />
            support@vanced-app.com
            <br /><br />
            <strong>Webseite</strong><br />
            <a href="http://www.vanced-app.com">http://www.vanced-app.com</a>
            <br /><br />
            <strong>Google Play</strong><br />
            <a
                href="https://play.google.com/store/apps/details?id=com.tv.live.deutsches.fernsehen"
                >https://play.google.com/store/apps/details?id=com.tv.live.deutsches.fernsehen</a
            >
            <br /><br />
            <strong>Privacy Policy</strong><br />
            <a href="http://www.vanced-app.com/privacy-policy.php"
                >http://www.vanced-app.com/privacy-policy.php</a
            >
            <br /><br />
            <strong>Version</strong><br />
            {{ version }}
        </b-modal>
        <b-modal v-model="modals.imprint.show" title="Impressum" hide-footer>
            <strong>GAGA TV</strong><br />
            Felix Klemm Internet UG (haftungsbeschränkt)<br />
            Lossastraße 11<br />
            04838 Eilenburg<br />
            Germany
            <br /><br />
            Email: info@gaga-tv-app.de
            <br />
            Ansprechpartner: M.Sc. Felix Klemm
            <br />
            <br />
            <b>Bitte beachten Sie:</b><br /><br />
            <b>Wir sind eine TV App und kein Sender.</b>
            <br /><br />
            Sie können bei uns:<br /><br />
            <ul>
                <li>
                    Keine Sendungen oder Dienstleistungen bestellen, buchen,
                    beantragen
                </li>
                <li>Keine Informationen über Sendungen in Erfahrung bringen</li>
            </ul>
            <br />
            Bitte wenden Sie sich bei Fragen zu Sendungen
            <b>direkt an den entsprechenden Sender.</b>
            <br />
            <br />
            web: <a href="https://gaga-tv-app.de">https://gaga-tv-app.de</a
            ><br />
            USt-IdNr. DE341727228<br />
            Tel.: 01579 / 2527570
            <br />
            <br />
            Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:
            DE341727228<br />
            Inhaltlich Verantwortlicher gemäß § 10 MDStV und
            Jugendschutzbeauftragter: Felix Klemm
        </b-modal>
        <b-modal
            v-model="modals.privacy.show"
            centered
            title="Datenschutz"
            hide-footer
        >
            Datenschutz hat für uns einen besonders hohen Stellenwert. Eine
            Nutzung von GAGATV ist grundsätzlich ohne jede Angabe persönlicher
            Daten möglich. <br />Hier finden Sie
            <a href="https://gaga-tv-app.de/datenschutz.php"
                >alle Infos zum Datenschutz</a
            >
        </b-modal>
        <b-modal
            v-model="modals.copyright.show"
            title="Copyright Hinweise"
            centered
            hide-footer
        >
            Alle Inhalte, einschließlich Bilder, die in dieser App verwendet
            werden, sind durch das Urheberrecht geschützt. Die Verwendung von
            Inhalten von tmdb.org und TV-Sendern erfolgt mit Erlaubnis und unter
            Beachtung der Nutzungsbedingungen der jeweiligen Quelle. Jede
            unerlaubte Verwendung oder Reproduktion der Inhalte ist untersagt.
            <br /><br />
            <a href="https://tmdb.org">tmdb.org</a>
        </b-modal>
    </div>
</template>
<script>
import Settings from '../../../views/Settings'
export default {
    ...Settings,
}
</script>
