<template>
    <div>
        <b-alert :show="hMediaLibrary === null" dismissible variant="primary" @dismissed="hideHint()">
            Alle Videos in der Mediathek können zu
            <strong>jeder Zeit</strong> abgespielt werden. Es kommen jeden Tag
            neue Videos hinzu.
        </b-alert>
        <div @click="hideHint">
            <h1
                class="h5 font-weight-bold mt-2 mb-2"
                @click="to('medialibrary-movies')"
            >
                Filme
                <font-awesome-icon
                    :icon="['fas', 'chevron-right']"
                    class="ml-2 small"
                />
            </h1>
            <loading v-if="$store.state.medialibrary.movies.loading" />
            <div v-else class="slider">
                <media-library-item
                    v-for="(item, index) in movies.filter(
                        (item, index) => index < 40
                    )"
                    :key="'a1-' + index"
                    :index="index"
                    :item="item"
                    :layout="2"
                />
                <div
                    class="shadow-sm bg-white m-1 p-3 d-flex flex-column justify-content-center align-items-center"
                    @click="to('medialibrary-movies')"
                >
                    <span
                        class="h5 font-weight-bold mb-0"
                        style="min-width: 100px"
                    >
                        Alle <br />anzeigen
                        <font-awesome-icon
                            :icon="['fas', 'chevron-right']"
                            class="ml-1"
                        />
                    </span>
                </div>
            </div>
            <h1
                class="h5 font-weight-bold mt-4 mb-2"
                @click="to('medialibrary-recommendations')"
            >
                Empfehlungen
                <font-awesome-icon
                    :icon="['fas', 'chevron-right']"
                    class="ml-2 small"
                />
            </h1>
            <loading v-if="$store.state.medialibrary.recommendations.loading" />
            <div v-else class="slider">
                <media-library-item
                    v-for="(item, index) in recommendations.filter(
                        (item, index) => index < 40
                    )"
                    :key="'b-' + index"
                    :index="index"
                    :item="item"
                    :layout="1"
                />
                <div
                    class="shadow-sm bg-white m-1 p-3 d-flex flex-column justify-content-center align-items-center"
                    @click="to('medialibrary-recommendations')"
                >
                    <span
                        class="h5 font-weight-bold mb-0"
                        style="min-width: 100px"
                    >
                        Alle <br />anzeigen
                        <font-awesome-icon
                            :icon="['fas', 'chevron-right']"
                            class="ml-1"
                        />
                    </span>
                </div>
            </div>
            <h1
                class="h5 font-weight-bold mt-4 mb-2"
                @click="to('medialibrary-channels')"
            >
                Kanäle
                <font-awesome-icon
                    :icon="['fas', 'chevron-right']"
                    class="ml-2 small"
                />
            </h1>
            <loading v-if="loading" />
            <div v-else class="slider">
                <channel
                    v-for="(item, index) in channels.filter(
                        (item, index) => index < 20
                    )"
                    :item="item"
                    :key="'channel-' + index"
                />
                <div
                    class="shadow-sm bg-white m-1 p-3 d-flex flex-column justify-content-center align-items-center"
                    @click="to('medialibrary-channels')"
                >
                    <div
                        style="min-width: 60px"
                        class="h5 font-weight-bold mb-0"
                    >
                        Alle
                        <font-awesome-icon
                            :icon="['fas', 'chevron-right']"
                            class="ml-1"
                        />
                    </div>
                </div>
            </div>

            <h1
                class="h5 font-weight-bold mt-4 mb-2"
                @click="to('medialibrary-latest')"
            >
                Neuste Videos
                <font-awesome-icon
                    :icon="['fas', 'chevron-right']"
                    class="ml-2 small"
                />
            </h1>
            <loading v-if="loading" />
            <div v-else class="slider">
                <media-library-item
                    v-for="(item, index) in latest.filter(
                        (item, index) => index < 40
                    )"
                    :key="'b-' + index"
                    :index="index"
                    :item="item"
                    :layout="1"
                />
                <div
                    class="shadow-sm bg-white m-1 p-3 d-flex flex-column justify-content-center align-items-center"
                    @click="to('medialibrary-latest')"
                >
                    <span
                        class="h5 font-weight-bold mb-0"
                        style="min-width: 100px"
                    >
                        Alle <br />anzeigen
                        <font-awesome-icon
                            :icon="['fas', 'chevron-right']"
                            class="ml-1"
                        />
                    </span>
                </div>
            </div>

            <h1 class="h5 font-weight-bold mt-4 mb-2">
                Genre
            </h1>
            <loading v-if="loading" />
            <div v-else>
                <div
                    v-for="(item, index) in genres"
                    :key="'c-' + index"
                    class="shadow-sm bg-white p-3 my-2 d-flex align-items-center"
                    @click="openGenreModal(item)"
                >
                    <div style="width: 45px">
                        <font-awesome-icon
                            v-if="item.icon !== undefined"
                            class="h2 mb-0 text-primary"
                            :icon="['fas', item.icon]"
                        />
                    </div>
                    <div>
                        <h1 class="h5 font-weight-bold mb-0">
                            {{ item.name }}
                        </h1>
                    </div>
                    <div class="pl-4 flex-fill text-right">
                        <font-awesome-icon :icon="['fas', 'chevron-right']" />
                    </div>
                </div>
            </div>
        </div>
        <b-modal
            v-model="modals.genre.show"
            scrollable
            hide-footer
            centered
            body-bg-variant="light"
            ref="genreModal"
        >
            <template #modal-header>
                <div
                    class="w-100 d-flex justify-content-between align-items-center"
                >
                    <div class="p-2 h5 mb-0" @click="modals.genre.show = false">
                        <font-awesome-icon :icon="['fas', 'arrow-left']" />
                    </div>
                </div>
            </template>
            <loading v-if="genreItemsLoading" />
            <div v-else>
                <media-library-item
                    v-for="(item, index) in genreItems"
                    :key="'a-' + index"
                    :item="item"
                    :index="index"
                    :layout="0"
                />
                <loading v-if="appendGenreItemsLoading" class="mt-2" />
            </div>
        </b-modal>
    </div>
</template>
<script>
import MediaLibraryIndex from '../../../views/MediaLibraryIndex'
export default {
    ...MediaLibraryIndex
}
</script>