import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const Today = require('./../variants/' + process.env.VUE_APP_VARIANT + '/views/Today').default
const Live = require('./../variants/' + process.env.VUE_APP_VARIANT + '/views/Live').default
const P2015 = require('./../variants/' + process.env.VUE_APP_VARIANT + '/views/P2015').default

const Program = require('./../variants/' + process.env.VUE_APP_VARIANT + '/views/Program').default
const Bookmarks = require('./../variants/' + process.env.VUE_APP_VARIANT + '/views/Bookmarks').default
const Recommendations = require('./../variants/' + process.env.VUE_APP_VARIANT + '/views/Recommendations').default
const Search = require('./../variants/' + process.env.VUE_APP_VARIANT + '/views/Search').default
const Tv = require('./../variants/' + process.env.VUE_APP_VARIANT + '/views/Tv').default
const Settings = require('./../variants/' + process.env.VUE_APP_VARIANT + '/views/Settings').default

const MediaLibrary = require('./../variants/' + process.env.VUE_APP_VARIANT + '/views/MediaLibrary').default
const MediaLibraryIndex = require('./../variants/' + process.env.VUE_APP_VARIANT + '/views/MediaLibraryIndex').default
const MediaLibraryChannels = require('./../variants/' + process.env.VUE_APP_VARIANT + '/views/MediaLibraryChannels').default
const MediaLibraryRecommendations = require('./../variants/' + process.env.VUE_APP_VARIANT + '/views/MediaLibraryRecommendations').default
const MediaLibraryLatest = require('./../variants/' + process.env.VUE_APP_VARIANT + '/views/MediaLibraryLatest').default
const MediaLibrarySeries = require('./../variants/' + process.env.VUE_APP_VARIANT + '/views/MediaLibrarySeries').default
const MediaLibraryMovies = require('./../variants/' + process.env.VUE_APP_VARIANT + '/views/MediaLibraryMovies').default

const Joyn = require('./../variants/' + process.env.VUE_APP_VARIANT + '/views/Joyn').default

const routes = [
    {
        path: '/today',
        name: 'today',
        component: Today,
        alias: '/',
    },
    {
        path: '/live',
        name: 'live',
        component: Live,
    },
    {
        path: '/2015',
        name: 'p2015',
        component: P2015,
    },
    {
        path: '/program',
        name: 'program',
        component: Program,
    },
    {
        path: '/search',
        name: 'search',
        component: Search,
    },
    {
        path: '/bookmarks',
        name: 'bookmarks',
        component: Bookmarks,
    },
    {
        path: '/recommendations',
        name: 'recommendations',
        component: Recommendations,
    },
    {
        path: '/tv',
        name: 'tv',
        component: Tv,
    },
    {
        path: '/joyn',
        name: 'joyn',
        component: Joyn,
    },
    {
        path: '/medialibrary',
        component: MediaLibrary,
        children: [
            {
                path: '',
                name: 'medialibrary',
                component: MediaLibraryIndex,
            },
            {
                path: 'channels',
                name: 'medialibrary-channels',
                component: MediaLibraryChannels,
            },
            {
                path: 'recommendations',
                name: 'medialibrary-recommendations',
                component: MediaLibraryRecommendations,
            },
            {
                path: 'movies',
                name: 'medialibrary-movies',
                component: MediaLibraryMovies,
            },
            {
                path: 'latest',
                name: 'medialibrary-latest',
                component: MediaLibraryLatest,
            },
            {
                path: 'series',
                name: 'medialibrary-series',
                component: MediaLibrarySeries,
            },
        ],
    },
    {
        path: '/settings',
        name: 'settings',
        component: Settings,
    },
]
const router = new VueRouter({
    scrollBehavior(to, from, savedPostion) {
        if (
            (from.query !== undefined && from.query.modal !== undefined) ||
            (to.query && to.query.modal !== undefined)
        ) {
            return savedPostion
        }
        return { x: 0, y: 0 }
    },
    mode: 'history',
    base: '/',
    routes,
})

export default router
