<template>
    <div>
        <b-alert
            :show="true"
            dismissible
            variant="primary"
            @dismissed="hideHint()"
        >
            💡 Tipp: Unsere Redaktion aktualisiert jeden Tag die Liste der
            Filme. Es lohnt sich also jeden Tag hier reinzuschauen.
        </b-alert>
        <div class="py-3 mb-1 mt-n2" style="overflow-x: auto">
            <div class="d-flex">
                <div
                    style="white-space: nowrap"
                    class="
                        border
                        bg-white
                        pointer
                        px-3
                        py-2
                        mr-1
                        font-weight-bold
                        rounded
                    "
                    :class="{
                        'border-dark': category.slug === selectedCategorySlug,
                    }"
                    v-for="category of categories"
                    :key="category.slug"
                    @click="selectCategory(category.slug)"
                >
                    {{ category.label }}
                </div>
            </div>
        </div>
        <loading v-if="loading" />
        <div v-else>
            <div v-if="items.length === 0">
                Unsere Redaktion sucht für dich gerade die besten Filme. Schau
                doch einfach später noch einmal vorbei.
            </div>
            <div v-else class="row row-smaller">
                <div
                    class="col-6 positiion-relative mb-3"
                    v-for="(item, index) of items"
                    :key="item.slug"
                >
                    <div
                        class="w-100 h-100 bg-white rounded"
                        @click="handleJoynItemClick(item.slug)"
                    >
                        <image-item
                            :index="index"
                            :source="item.image_url"
                            class="w-100 mb-2"
                        />
                        <div class="p-1">
                            <div class="small d-flex align-items-center">
                                <div style="white-space: nowrap">
                                    <font-awesome-icon
                                        :icon="['fas', 'star']"
                                        v-for="n in Math.ceil(
                                            item.vote_average / 2
                                        )"
                                        :key="'aacc' + n"
                                    />
                                    <font-awesome-icon
                                        :icon="['far', 'star']"
                                        v-for="n in 5 -
                                        Math.ceil(item.vote_average / 2)"
                                        :key="'aaacc' + n"
                                    />
                                </div>
                                <span
                                    class="ml-1 text-muted"
                                    style="
                                        overflow: hidden;
                                        white-space: nowrap;
                                    "
                                    >{{ item.vote_count }} Stimmen</span
                                >
                            </div>
                            <strong>{{
                                item.title.length > 40
                                    ? item.title.substring(0, 40) + '...'
                                    : item.title
                            }}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-modal
            v-model="modal.show"
            no-close-on-backdrop
            header-bg-variant="dark"
            centered
            modal-class="modal-fullscreen"
            body-class=""
            ok-only
        >
            <template #modal-header>
                <div
                    class="
                        text-white text-center
                        rounded
                        w-100
                        p-1
                        h5
                        mb-0
                        font-weight-bold
                    "
                >
                    💡 Filmtipp des Tages
                </div>
            </template>
            <div v-if="Object.keys(modal.movie).length" @click="startMovie">
                <image-item
                    :index="0"
                    :source="modal.movie.image_url"
                    class="w-100"
                />
                <div class="mt-1">
                    <div class="d-flex align-items-center">
                        <div style="white-space: nowrap">
                            <font-awesome-icon
                                :icon="['fas', 'star']"
                                v-for="n in Math.ceil(
                                    modal.movie.vote_average / 2
                                )"
                                :key="'aacc' + n"
                            />
                            <font-awesome-icon
                                :icon="['far', 'star']"
                                v-for="n in 5 -
                                Math.ceil(modal.movie.vote_average / 2)"
                                :key="'aaacc' + n"
                            />
                        </div>
                        <span
                            class="ml-2 text-muted"
                            style="overflow: hidden; white-space: nowrap"
                            >{{ modal.movie.vote_count }} Bewertungen</span
                        >
                    </div>
                    <div>
                        <span
                            class="badge badge-secondary mr-1"
                            v-for="item of modal.movie.genres.split(', ')"
                            >{{ item }}</span
                        >
                    </div>
                    <strong style="color: #000">{{ modal.movie.title }}</strong>
                    <p class="text-muted small mb-0">
                        {{ modal.movie.description }}
                    </p>
                </div>
            </div>
            <template #modal-footer>
                <button class="btn btn-block btn-success" @click="startMovie()">
                    ▶ Kostenlos starten
                </button>
                <button
                    class="btn btn-block text-dark btn-link"
                    @click="hideModal()"
                >
                    andere Filme anzeigen
                </button>
            </template>
        </b-modal>
    </div>
</template>
<script>
import Joyn from '../../../views/Joyn'
export default {
    ...Joyn,
}
</script>
