<template>
    <div>
        <loading v-if="$store.state.program.loading" />
        <div v-else>
            <div
                class="bg-white shadow-sm p-3 rating"
                v-if="banner.rating.show"
            >
                <!-- userCreatedAt.diff(new Date, 'days') <= -1 -->
                <h1 class="h4 font-weight-bold mb-2">Gefällt dir die App?</h1>
                <div class="d-flex">
                    <button
                        type="button"
                        class="btn btn-outline-primary mr-2"
                        style="width: 120px"
                        @click="bannerRatingActionA()"
                    >
                        Ja
                    </button>
                    <button
                        type="button"
                        class="btn btn-outline-primary"
                        style="width: 120px"
                        @click="bannerRatingActionB()"
                    >
                        Nein
                    </button>
                </div>
            </div>

            <div
                class="alert alert-dismissible shadow-sm bg-white my-3"
                v-if="banner.rating.action.a"
            >
                <button
                    type="button"
                    class="close"
                    @click="bannerRatingActionClose()"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
                <h1 class="h4 font-weight-bold mb-2">
                    <font-awesome-icon
                        :icon="['fas', 'star-half-alt']"
                        class="mr-1"
                    />
                    Bitte bewerte uns
                </h1>
                <p class="mb-1">
                    Wir würden uns sehr freuen wenn du dir kurz Zeit nimmst und
                    uns 5 Sterne in Google Play geben würdest.
                </p>
                <button
                    type="button"
                    class="btn btn-block btn-outline-primary"
                    @click="bannerRatingActionAA()"
                >
                    Jetzt Bewertung abgeben
                </button>
            </div>

            <div
                v-if="banner.payment.show && !subscription"
                class="alert alert-dismissible shadow-sm bg-white my-3"
            >
                <button type="button" class="close" @click="bannerAdClose()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h1 class="h5 font-weight-bold mb-2">
                    <font-awesome-icon :icon="['fas', 'ad']" class="mr-1" />
                    Pro Version
                </h1>
                <div @click="bannerAdActionA()">
                    <p class="mb-1">
                        Hol dir jetzt GAGA TV ohne Werbung. Einmalige Zahlung.
                        Dauerhaft werbefrei.
                    </p>
                    <button
                        type="button"
                        class="btn btn-outline-primary"
                        @click="bannerAdActionA()"
                    >
                        Mehr Infos
                    </button>
                </div>
            </div>
            <div class="my-2">
                <button
                    class="btn mr-2"
                    :class="{
                        'btn-primary': selected == 'pNow',
                        'btn-outline-primary': selected != 'pNow',
                    }"
                    @click="selected = 'pNow'"
                >
                    Jetzt
                </button>
                <button
                    class="btn mr-2"
                    :class="{
                        'btn-primary': selected == 'pAfter',
                        'btn-outline-primary': selected != 'pAfter',
                    }"
                    @click="selected = 'pAfter'"
                >
                    Danach
                </button>
                <button
                    class="btn mr-2"
                    :class="{
                        'btn-primary': selected == 'p2015',
                        'btn-outline-primary': selected != 'p2015',
                    }"
                    @click="selected = 'p2015'"
                >
                    20:15
                </button>
                <button
                    class="btn"
                    :class="{
                        'btn-primary': selected == 'p2200',
                        'btn-outline-primary': selected != 'p2200',
                    }"
                    @click="selected = 'p2200'"
                >
                    22:00
                </button>
            </div>
            <b-alert
                :show="hTodayLive === null"
                dismissible
                variant="primary"
                @dismissed="handleClick()"
            >
                Tippe auf
                <font-awesome-icon :icon="['fas', 'chevron-right']" />
                um den Livestream zu starten.
            </b-alert>
            <div v-if="selected == 'pNow'">
                <div
                    v-for="(item, index) in pNow"
                    :key="index"
                    @click="handleClick()"
                >
                    <broadcast
                        :layout="BROADCAST_LAYOUT_LIVE"
                        :item="item"
                        :c="item.c"
                    />
                </div>
            </div>
            <div v-if="selected == 'pAfter'">
                <div
                    v-for="(item, index) in pAfter"
                    :key="index"
                    @click="handleClick()"
                >
                    <broadcast
                        :layout="BROADCAST_LAYOUT_LIVE"
                        :item="item"
                        :c="item.c"
                    />
                </div>
            </div>
            <div v-if="selected == 'p2015'">
                <div
                    v-for="(item, index) in p2015"
                    :key="index"
                    @click="handleClick()"
                >
                    <broadcast
                        :layout="BROADCAST_LAYOUT_LIVE"
                        :item="item"
                        :c="item.c"
                    />
                </div>
            </div>
            <div v-if="selected == 'p2200'">
                <div
                    v-for="(item, index) in p2200"
                    :key="index"
                    @click="handleClick()"
                >
                    <broadcast
                        :layout="BROADCAST_LAYOUT_LIVE"
                        :item="item"
                        :c="item.c"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Live from '../../../views/Live'
export default {
    ...Live,
}
</script>
