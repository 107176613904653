var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$store.state.program.loading)?_c('loading'):_c('div',[_c('div',{staticClass:"my-2"},[(
                    _vm.dayjs().isBefore(
                        _vm.dayjs()
                            .startOf('day')
                            .add(22, 'hours')
                    ) &&
                        _vm.dayjs().isAfter(
                            _vm.dayjs()
                                .startOf('day')
                                .add(5, 'hours')
                        )
                )?_c('button',{staticClass:"btn mr-2",class:{
                    'btn-primary': _vm.selected == 'p2015',
                    'btn-outline-primary': _vm.selected != 'p2015',
                },on:{"click":function($event){_vm.selected = 'p2015'}}},[_vm._v(" ab 20:15 ")]):_vm._e(),(
                    _vm.dayjs().isBefore(
                        _vm.dayjs()
                            .startOf('day')
                            .add(23, 'hours')
                    ) &&
                        _vm.dayjs().isAfter(
                            _vm.dayjs()
                                .startOf('day')
                                .add(5, 'hours')
                        )
                )?_c('button',{staticClass:"btn mr-2",class:{
                    'btn-primary': _vm.selected == 'p2200',
                    'btn-outline-primary': _vm.selected != 'p2200',
                },on:{"click":function($event){_vm.selected = 'p2200'}}},[_vm._v(" ab 22:00 ")]):_vm._e(),_c('button',{staticClass:"btn",class:{
                    'btn-primary': _vm.selected == 'p2300',
                    'btn-outline-primary': _vm.selected != 'p2300',
                },on:{"click":function($event){_vm.selected = 'p2300'}}},[_vm._v(" ab 23:00 ")])]),_c('b-alert',{attrs:{"show":_vm.hToday2015 === null &&
                    _vm.dayjs().isBefore(
                        _vm.dayjs()
                            .startOf('day')
                            .add(20, 'hours')
                            .add(5, 'minutes')
                    ),"dismissible":"","variant":"primary"},on:{"dismissed":function($event){return _vm.handleClick()}}},[_vm._v(" Tippe auf "),_c('font-awesome-icon',{attrs:{"icon":['far', 'heart']}}),_vm._v(" um "),_c('strong',[_vm._v("10 Minuten vor Beginn")]),_vm._v(" der Sendung benachrichtigt zu werden. ")],1),_vm._l((_vm.rows),function(item,index){return _c('div',{key:index,on:{"click":function($event){return _vm.handleClick()}}},[_c('broadcast',{attrs:{"layout":_vm.BROADCAST_LAYOUT_LIVE,"item":item,"c":item.c}})],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }